const initialState = {
  sidebarShow: true,
  narrow: false,
  transition: false,
}

export const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'transitionEnd':
      if (state.transition) {
        return { ...state, transition: false }
      }
      return state
    default:
      return state
  }
}
