import moment from 'moment'
import { formatInputDate, formatBoolean } from 'src/utils/formatters'
import columnsSizes from './columnsSizes'
import { WORK_DONE } from './constants'

export const TASK = {
  id: { label: 'Номер', name: 'id', ordering: true, width: columnsSizes.tiny, css: 'text-center' },
  source: { label: 'Источник', name: 'source', width: columnsSizes.number, css: 'text-center' },
  city: {
    label: 'Город',
    name: 'city',
    ordering: true,
    width: 76,
    css: 'w-100 white-space-no-wrap',
  },
  operator: { label: 'Оператор', name: 'operator', width: columnsSizes.short_text },
  category: {
    label: 'Категория',
    name: 'category',
    ordering: true,
    width: columnsSizes.short_text,
  },
  legal_entity: {
    label: 'Организация',
    name: 'legal_entity',
    width: columnsSizes.short_text,
    css: 'text-center',
  },
  master: { label: 'Мастер', name: 'master', ordering: true, width: columnsSizes.short_text },
  status: {
    label: 'Статус',
    name: 'status',
    ordering: true,
    component: 'badge',
    width: 260,
  },
  phone: {
    label: 'Телефон',
    name: 'phone',
    css: 'fw-bold',
    ordering: true,
    width: columnsSizes.phone,
  },
  creator: {
    label: 'Создавший',
    name: 'creator',
    ordering: true,
    width: columnsSizes.short_text,
  },
  created_date: {
    label: 'Дата постановки',
    name: 'created_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    ordering: true,
    width: columnsSizes.date,
  },
  due_date: {
    label: 'Ожидаемая дата выполнения',
    name: 'due_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    ordering: true,
    width: columnsSizes.short_text,
    css: 'fw-bold',
  },
  done_date: {
    label: 'Дата выполнения',
    name: 'done_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    ordering: true,
    width: columnsSizes.date,
  },
  call_date: {
    label: 'Дата звонка',
    name: 'call_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    width: columnsSizes.date,
  },
  description: {
    label: 'Описание',
    name: 'description',
    ordering: true,
    width: columnsSizes.long_text,
    css: 'text-truncate',
    expandable: true,
  },
  address: { label: 'Адрес', name: 'address', width: columnsSizes.short_text },
  address_comment: {
    label: 'Комментраии к адресу',
    name: 'address_comment',
    width: columnsSizes.long_text,
  },
  result_price: {
    label: 'Общая стоимость',
    name: 'result_price',
    ordering: true,
    width: columnsSizes.number,
    css: 'text-center',
  },
  company_income: {
    label: 'Доход компании',
    name: 'company_income',
    width: columnsSizes.number,
    ordering: true,
    css: 'text-center',
  },
  rate: {
    label: 'Оценка',
    name: 'rate',
    component: 'rating',
    width: columnsSizes.tiny,
    css: 'text-center',
  },
  master_photo: {
    label: 'Есть фото мастера',
    width: columnsSizes.tiny,
    css: 'text-center',
    transform: formatBoolean,
  },
  creator_photo: {
    label: 'Есть фото клиента',
    width: columnsSizes.tiny,
    css: 'text-center',
    transform: formatBoolean,
  },
  has_comments: {
    label: 'Комментарии',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: formatBoolean,
  },
  rate_comment: {
    label: 'Отзыв',
    width: columnsSizes.tiny,
    css: 'text-center',
    transform: formatBoolean,
  },
  tariff_changed: {
    label: 'Смена тарифа',
    width: columnsSizes.tiny,
    css: 'text-center',
    transform: formatBoolean,
  },
}

export const ENTITY_TASK = {
  id: { label: 'Номер', name: 'id', ordering: true, width: columnsSizes.tiny, css: 'text-center' },
  city: {
    label: 'Город',
    name: 'city',
    ordering: true,
    width: 76,
    css: 'w-100 white-space-no-wrap',
  },
  category: {
    label: 'Категория',
    name: 'category',
    ordering: true,
    width: columnsSizes.short_text,
  },
  master: { label: 'Мастер', name: 'master', ordering: true, width: columnsSizes.short_text },
  status: {
    label: 'Статус',
    name: 'status',
    ordering: true,
    component: 'badge',
    width: 260,
  },
  operator: { label: 'Счет', name: 'operator', width: columnsSizes.tiny },
  phone: {
    label: 'Телефон',
    name: 'phone',
    css: 'fw-bold',
    ordering: true,
    width: columnsSizes.phone,
  },
  created_date: {
    label: 'Дата постановки',
    name: 'created_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    ordering: true,
    width: columnsSizes.date,
  },
  due_date: {
    label: 'Ожидаемая дата выполнения',
    name: 'due_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    ordering: true,
    width: columnsSizes.short_text,
    css: 'fw-bold',
  },
  done_date: {
    label: 'Дата выполнения',
    name: 'done_date',
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
    ordering: true,
    width: columnsSizes.date,
  },
  description: {
    label: 'Описание',
    name: 'description',
    ordering: true,
    width: columnsSizes.long_text,
    css: 'text-truncate',
    expandable: true,
  },
  address: { label: 'Адрес', name: 'address', width: columnsSizes.short_text },
  address_comment: {
    label: 'Комментраии к адресу',
    name: 'address_comment',
    width: columnsSizes.long_text,
  },
}

export const TASK_UPDATE = {
  refuse_reason: { label: 'Причина отказа', name: 'refuse_reason' },
  street: { label: 'Улица', name: 'street' },
  house_number: { label: 'Дом', name: 'house_number' },
  block: { label: 'Блок', name: 'block' },
  flat: { label: '№ кв.', name: 'flat' },
  address_comment: { label: 'Комментарий адреса', name: 'address_comment' },
  work_price: { label: 'Стоимость работ', name: 'work_price' },
  comment: { label: 'Коммент', name: 'comment' },
}

export const TASK_STATUS_TRANSLATER = [
  {
    id: 'WAIT_CALL',
    name: 'ожидайте звонка в течение 10 минут',
    disabled: true,
    isAwailableForSearch: true,
  },
  { id: 'LOOKING_FOR_MASTER', name: 'ищем мастера', isAwailableForSearch: true },
  { id: 'ACCEPTED', name: 'принят мастером', disabled: true },
  { id: 'IN_WORK', name: 'в работе', isAwailableForSearch: true },
  { id: 'CLIENT_THINKING', name: 'клиент думает', isAwailableForSearch: true },
  { id: 'DONE_CONFIRMED', name: 'выполнение подтверждено', disabled: true },
  {
    id: 'MASTER_NOT_AVAILABLE',
    name: 'мастер недоступен',
    disabled: true,
    isAwailableForSearch: true,
  },
  { id: WORK_DONE, name: 'работа окончена', isAwailableForSearch: true },
  { id: 'PROCESSING', name: 'добавлено', isAwailableForSearch: true, disabled: true },
  { id: 'REFUSE_CLIENT', name: 'клиент отказался', isAwailableForSearch: true },
  { id: 'REFUSE_COMPANY', name: 'компания отказалась', isAwailableForSearch: true },
  { id: 'REFUSE_MASTER', name: 'мacтер отказался', isAwailableForSearch: true },
  { id: 'REFUSE_USER', name: 'отменено пользователем', disabled: true },
]

export const ROLE_TRANSLATER = [
  { id: 4, name: 'Директор' },
  { id: 2, name: 'Macтep' },
  { id: 3, name: 'Оператор' },
]

export const ROUTE_TRANSLATER = {
  update: 'Редактировать',
  edit: 'Редактировать',
  tasks: 'Задания',
  staff: 'Сотрудники',
  address: 'Адрес',
  create: 'Добавить',
  reports: 'Отчеты',
  registry: 'Справочники',
  users: 'Пользователи',
  management: 'Управление',
  tariffs: 'Тарифы',
  settings: 'Настройки',
  'update-extra': 'Доп.Правило',
}

export const CITY = {
  id: { label: 'Номер', name: 'id', width: '3%', css: 'text-center' },
  name: { label: 'Город', name: 'name', width: columnsSizes.medium_text },
  timezone: { label: 'Временная зона', name: 'timezone', width: columnsSizes.medium_text },
  comment: { label: 'Комментарий', name: 'comment', width: columnsSizes.long_text },
}

const client = {
  id: {
    label: 'Номер',
    name: 'id',
    ordering: true,
    css: 'text-center',
    width: columnsSizes.tiny,
  },
  is_active: {
    label: 'Активен',
    name: 'active',
    transform: (val) => {
      if (val === true) {
        return 'да'
      }
      return 'нет'
    },
    css: 'text-center',
    ordering: 'true',
    width: 76,
  },
  phone_number: {
    label: 'Телефон',
    name: 'phone_number',
    ordering: true,
    width: columnsSizes.phone,
  },
  first_name: { label: 'Имя', name: 'first_name', width: columnsSizes.short_text },
  city: { label: 'Город', name: 'city', ordering: true, width: columnsSizes.short_text },
  address: {
    label: 'Адрес',
    name: 'address',
    transform: (val) => {
      return val.map((addr) => addr.text)
    },
    width: columnsSizes.short_text,
  },
  email: { label: 'Email', name: 'email', ordering: true, width: columnsSizes.short_text },
  last_request_date: {
    label: 'Последнее обращение',
    name: 'last_request_date',
    width: columnsSizes.date,
    css: 'text-center',
  },
  tasks_count: {
    label: 'Кол-во заданий',
    name: 'tasks_count',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.phone,
  },
  tasks: {
    label: 'Задания',
    name: 'tasks',
    width: columnsSizes.long_text,
    numberList: true,
    expandable: true,
  },
  summa: {
    label: 'Сумма',
    name: 'summa',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
  },
  income: {
    label: 'Доход',
    name: 'income',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
  },
  comment: {
    label: 'Комментарий',
    name: 'comment',
    width: columnsSizes.long_text,
    css: 'scrollable',
  },
}

export const CLIENT = client

export const LEGAL_ENTITY = {
  id: {
    label: 'Номер',
    name: 'id',
    ordering: true,
    css: 'text-center',
    width: columnsSizes.tiny,
  },
  active: {
    label: 'Активен',
    name: 'active',
    transform: (val) => {
      if (val === true) {
        return 'да'
      }
      return 'нет'
    },
    css: 'text-center',
    ordering: true,
    width: 76,
  },
  city: { label: 'Город', name: 'city', ordering: true, width: columnsSizes.short_text },
  name_for_app: {
    label: 'Наименование в приложении',
    name: 'name_for_app',
    ordering: true,
    width: columnsSizes.short_text,
  },
  name_for_documents: {
    label: 'Наименование в документах',
    name: 'name_for_documents',
    ordering: true,
    width: columnsSizes.short_text,
  },
  inn: {
    label: 'ИНН',
    name: 'inn',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
  },
  kpp: {
    label: 'КПП',
    name: 'kpp',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
  },
  ogrn: {
    label: 'ОГРН',
    name: 'ogrn',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
  },
  bank_name: {
    label: 'Наименование банка',
    name: 'bank_name',
    width: columnsSizes.short_text,
  },
  index: {
    label: 'Индекс',
    name: 'index',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  has_edo: {
    label: 'Есть ЭДО',
    name: 'has_edo',
    transform: formatBoolean,
    css: 'text-center',
    ordering: true,
    width: 78,
  },
  edo_name: {
    label: 'Наименование ЭДО',
    name: 'edo_name',
    width: columnsSizes.medium_text,
  },
  phone: { label: 'Телефон', name: 'phone', width: columnsSizes.phone },
  email: { label: 'Email', name: 'email', width: columnsSizes.short_text },
}

export const MASTER = {
  id: {
    label: 'Номер',
    name: 'id',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  is_active: {
    label: 'Активен',
    name: 'is_active',
    transform: formatBoolean,
    css: 'text-center',
    ordering: true,
    width: 78,
  },
  last_name: {
    label: 'Фамилия',
    name: 'last_name',
    ordering: true,
    width: columnsSizes.short_text,
  },
  first_name: { label: 'Имя', name: 'first_name', ordering: true, width: columnsSizes.short_text },
  middle_name: {
    label: 'Отчество',
    name: 'middle_name',
    ordering: true,
    width: columnsSizes.short_text,
  },
  city: { label: 'Город', name: 'city', ordering: true, width: columnsSizes.short_text },
  phone_number: { label: 'Телефон', name: 'phone_number', width: columnsSizes.phone },
  email: { label: 'Email', name: 'email', width: columnsSizes.short_text },
  role: {
    label: 'Должность',
    name: 'role',
    width: columnsSizes.date,
    css: 'text-center',
  },
  onwork: {
    label: 'На смене',
    name: 'onwork',
    transform: formatBoolean,
    css: 'text-center',
    width: columnsSizes.tiny,
  },
  receive_notifications: {
    label: 'Уведомления',
    name: 'receive_notifications',
    transform: formatBoolean,
    css: 'text-center',
    width: columnsSizes.number,
  },
  categories: {
    label: 'Категории',
    name: 'categories',
    width: columnsSizes.long_text,
    expandable: true,
  },
  has_uncalled_task: {
    label: 'Задания невыполнены ',
    name: 'has_uncalled_task',
    transform: formatBoolean,
    css: 'text-center',
    width: columnsSizes.tiny,
  },
  priority: {
    label: 'Приоритет',
    name: 'priority',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  priority_locked: {
    label: 'Приоритет зафиксирован',
    name: 'priority_locked',
    transform: formatBoolean,
    css: 'text-center',
    width: columnsSizes.tiny,
  },
  balance: {
    label: 'Баланс',
    name: 'balance',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  tasks_count: {
    label: 'Кол-во заданий',
    name: 'tasks_count',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  tasks_done: {
    label: 'Заданий выполнено',
    name: 'tasks_done',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  temporary_not_work: {
    label: 'Не работает',
    name: 'temporary_not_work',
    transform: formatBoolean,
    css: 'text-center',
    width: columnsSizes.tiny,
  },
  scores: {
    label: 'Баллы',
    name: 'scores',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  average_rate: {
    label: 'Средняя оценка',
    name: 'average_rate',
    css: 'text-center',
    width: columnsSizes.number,
    transform: (val) => {
      if (!val) return '-'
      return val
    },
  },
}

const crmClient = { ...client }
delete crmClient.city
export const CRM_CLIENT = crmClient

export const PAYMENT_RULES = {
  id: { label: 'Номер', name: 'id', width: columnsSizes.tiny, css: 'text-center', ordering: true },
  active: {
    label: 'Активно',
    name: 'active',
    transform: formatBoolean,
    css: 'text-center',
    width: 76,
    ordering: true,
  },
  is_extra_rule: {
    label: 'Доп.правило',
    name: 'is_extra_rule',
    width: columnsSizes.number,
    css: 'text-center',
    transform: formatBoolean,
  },
  name: { label: 'Название', name: 'name', width: columnsSizes.short_text, ordering: true },
  position: {
    label: 'Позиция',
    name: 'position',
    width: columnsSizes.number,
    ordering: true,
    css: 'text-center',
  },
  percent: {
    label: 'Процент',
    name: 'percent',
    width: columnsSizes.number,
    ordering: true,
    transform: (val) => `${val}%`,
    css: 'text-center',
  },
  category: {
    label: 'Категория',
    name: 'category',
    width: columnsSizes.long_text,
  },
  tariff_set: {
    label: 'Тарифы',
    name: 'tariff_set',
    width: columnsSizes.number,
  },
  description: {
    label: 'Описание',
    name: 'description',
    width: columnsSizes.long_text,
    expandable: true,
  },
}

export const HANDBOOK_STATUSES_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: '3%', css: 'text-center' },
  text: { label: 'Текст', name: 'text', width: columnsSizes.long_text },
  description: { label: 'Описание', name: 'description', width: '70%' },
}

export const HANDBOOK_CATEGORIES_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: '3%', css: 'text-center', ordering: true },
  order: {
    label: 'Порядок',
    name: 'order',
    width: columnsSizes.tiny,
    css: 'text-center',
    ordering: true,
  },
  name: { label: 'Название', name: 'name', width: columnsSizes.long_text, ordering: true },
  parent: {
    label: 'Группа',
    name: 'parent',
    width: columnsSizes.medium_text,
    transform: formatBoolean,
  },
  city: {
    label: 'Город',
    name: 'city',
    width: 156,
  },
  city_to_show: {
    label: 'Доступно для мобильных приложений',
    name: 'city_to_show',
    width: columnsSizes.medium_text,
  },
  description: { label: 'Описание', name: 'description' },
}

export const EMPLOYEE_TRASLATER = {
  id: { label: 'Номер', name: 'id', ordering: true, width: columnsSizes.tiny, css: 'text-center' },
  online: {
    label: 'Online',
    name: 'online',
    transform: formatBoolean,
    ordering: true,
    width: columnsSizes.tiny,
    css: 'text-center',
  },
  role: {
    label: 'Должность',
    name: 'role',
    width: columnsSizes.date,
    ordering: true,
    css: 'text-center',
  },
  is_active: {
    label: 'Активен',
    name: 'active',
    transform: formatBoolean,
    css: 'text-center',
    ordering: true,
    width: 76,
  },
  city: {
    label: 'Город',
    name: 'city',
    width: columnsSizes.short_text,
  },
  first_name: { label: 'Имя', name: 'first_name', ordering: true, width: columnsSizes.short_text },
  middle_name: {
    label: 'Отчество',
    name: 'middle_name',
    ordering: true,
    width: columnsSizes.short_text,
  },
  last_name: {
    label: 'Фамилия',
    name: 'last_name',
    ordering: true,
    width: columnsSizes.short_text,
  },
  birthday: {
    label: 'Дата рождения',
    name: 'birthday',
    width: columnsSizes.date,
    transform: (val) => {
      const dateString = formatInputDate(val)
      if (dateString === 'Invalid date') {
        return null
      }
      return dateString
    },
  },
  email: { label: 'Email', name: 'email', width: columnsSizes.short_text },
  phone: { label: 'Номер тел.', name: 'phone', width: columnsSizes.phone },
  comment: {
    label: 'Комментарий',
    name: 'comment',
    limit: 40,
    width: columnsSizes.long_text,
    expandable: true,
  },
}

const diagnosticTariffs = {
  id: { label: 'Номер', name: 'id', ordering: true, width: columnsSizes.tiny, css: 'text-center' },
  active: {
    label: 'Активен',
    name: 'active',
    transform: formatBoolean,
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  price: {
    label: 'Стоимость',
    name: 'price',
    width: columnsSizes.number,
    css: 'text-center',
  },
  city: {
    label: 'Город',
    name: 'city',
    width: '200px',
  },
  category: {
    label: 'Категория',
    name: 'category',
    width: columnsSizes.long_text,
  },
  description: { label: 'Описание', name: 'description' },
}

export const DIAGNOSTIC_TARIFFS_TRANSLATER = { ...diagnosticTariffs }

export const TARIFFS_TRANSLATER = {
  id: { label: 'Номер', name: 'id', ordering: true, width: columnsSizes.tiny, css: 'text-center' },
  active: {
    label: 'Активен',
    name: 'active',
    transform: formatBoolean,
    css: 'text-center',
    ordering: true,
    width: columnsSizes.tiny,
  },
  name: { label: 'Название', name: 'name', ordering: true, width: columnsSizes.short_text },
  ...diagnosticTariffs,
}

export const TASK_LOG_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: columnsSizes.tiny, css: 'text-center' },
  user: { label: 'Пользователь', name: 'user', width: columnsSizes.long_text },
  action_time: {
    label: 'Время',
    name: 'action_time',
    width: columnsSizes.short_text,
    transform: (val) => {
      return moment(val).format('DD.MM.YYYY HH:MM')
    },
  },
  action_type: { label: 'Действие', name: 'action_type', width: columnsSizes.short_text },
  data: {
    label: 'Параметры запроса',
    name: 'data',
    transform: (data) => JSON.stringify(data, null, 2),
  },
}

export const TASK_VISAS_TRANSLATER = {
  text: { label: 'Виза', name: 'text', width: columnsSizes.long_text },
  created: {
    label: 'Проставлено',
    name: 'created',
    width: columnsSizes.short_text,
    transform: (val) => {
      return moment(val).format('DD.MM.YYYY HH:MM')
    },
  },
  owner: { label: 'Автор', name: 'owner', width: columnsSizes.tiny },
}

export const GLOBAL_LOGS_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: columnsSizes.tiny, css: 'text-center' },
  action_time: {
    label: 'Время',
    name: 'action_time',
    width: columnsSizes.short_text,
    transform: (val) => {
      return moment(val).format('DD.MM.YYYY HH:MM')
    },
    css: 'text-center',
  },
  user: { label: 'Пользователь', name: 'user', width: columnsSizes.medium_text },
  action_type: { label: 'Действие', name: 'action_type', width: columnsSizes.short_text },
  object_id: {
    label: 'Номер объекта',
    name: 'object_id',
    width: columnsSizes.tiny,
    css: 'text-center',
  },
  object_type: { label: 'Тип объекта', name: 'object_type', width: columnsSizes.short_text },
  data: {
    label: 'Параметры запроса',
    name: 'data',
    transform: (data) => JSON.stringify(data, null, 2),
  },
}

export const FEEDBACK_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: columnsSizes.tiny, css: 'text-center' },
  created: {
    label: 'Дата',
    name: 'created',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: (val) => {
      return moment(val).format('DD.MM.YYYY HH:mm')
    },
  },
  processing_date: {
    label: 'Обработано',
    name: 'processing_date',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: (val) => {
      const date = moment(val).format('DD.MM.YYYY HH:mm')
      if (date === 'Invalid date') {
        return null
      }
      return date
    },
  },
  text: { label: 'Текст', name: 'text', truncate: true, tooltip: true },
  name: { label: 'Имя', name: 'name', width: columnsSizes.short_text, css: 'text-center' },
  city: {
    label: 'Город',
    name: 'city',
    width: columnsSizes.number,
    css: 'text-center',
  },
  role: { label: 'Тип', name: 'role', width: columnsSizes.number, css: 'text-center' },
  phone_number: {
    label: 'Телефон',
    name: 'phone_number',
    width: columnsSizes.phone,
    css: 'text-center',
  },
  email: { label: 'Email', name: 'email', width: columnsSizes.short_text },
}

export const NOTIFICATIONS_TRANSLATER = {
  id: {
    label: 'Номер',
    name: 'id',
    width: columnsSizes.tiny,
    css: 'text-center',
    ordering: true,
    truncate: true,
  },
  created: {
    label: 'Дата',
    name: 'created',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: (val) => {
      return moment(val).format('DD.MM.YYYY HH:mm')
    },
    ordering: true,
    truncate: true,
  },
  task: {
    label: 'Номер задачи',
    name: 'task',
    width: columnsSizes.short_text,
    css: 'text-center text-truncate',
  },
  processed_date: {
    label: 'Обработано',
    name: 'processed_date',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: (val) => {
      const date = moment(val).format('DD.MM.YYYY HH:mm')
      if (date === 'Invalid date') {
        return null
      }
      return date
    },
    truncate: true,
  },
  operator: {
    label: 'Оператор',
    name: 'operator',
    width: columnsSizes.short_text,
    truncate: true,
  },
  master: {
    label: 'Мастер',
    name: 'master',
    width: columnsSizes.short_text,
    transform: (val) => {
      return val?.short_name || ''
    },
    showModal: true,
    truncate: true,
  },
  city: {
    label: 'Город',
    name: 'city',
    width: columnsSizes.number,
    css: 'text-center',
    truncate: true,
  },
  text: {
    label: 'Текст',
    name: 'text',
    truncate: true,
    tooltip: true,
    width: '100%',
  },
}

export const TRANSACTIONS_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: columnsSizes.tiny, css: 'text-center', ordering: true },
  datetime: {
    label: 'Дата',
    name: 'datetime',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: (val) => {
      return moment(val).format('DD.MM.YYYY HH:mm')
    },
  },
  city: {
    label: 'Город',
    name: 'city',
    width: columnsSizes.medium_text,
  },
  master: {
    label: 'Мастер',
    name: 'master',
    width: columnsSizes.long_text,
  },
  value: {
    label: 'Сумма платежа',
    name: 'value',
    width: columnsSizes.short_text,
    ordering: true,
  },
  type: {
    label: 'Тип платежа',
    name: 'type',
    truncate: true,
  },
  task_id: {
    label: 'Номер задания',
    name: 'task_id',
    width: columnsSizes.numder,
    css: 'text-center',
  },
}

export const SUPPORT_CONTACTS_TRANSLATER = {
  id: { label: 'Номер', name: 'id', width: columnsSizes.tiny, css: 'text-center' },
  city: {
    label: 'Город',
    name: 'city',
    css: 'text-center',
  },
  phone: {
    label: 'Телефон',
    name: 'phone',
    width: columnsSizes.phone,
  },
  email: { label: 'Email', name: 'email', css: 'text-center' },
}

export const OPERATORS_ONWORK_TRANSLATER = {
  fio: {
    label: 'ФИО',
    name: 'fio',
    width: columnsSizes.medium_text,
    ordering: true,
    expandable: true,
  },
  city: {
    label: 'Город',
    name: 'city',
    width: columnsSizes.medium_text,
    expandable: true,
    ordering: true,
  },
}

export const MASTERS_ONWORK_TRANSLATER = {
  fio: {
    label: 'ФИО',
    name: 'fio',
    width: columnsSizes.short_text,
    ordering: true,
  },
  city: {
    label: 'Город',
    name: 'city',
    width: columnsSizes.short_text,
    ordering: true,
  },
  categories: {
    label: 'Категории',
    name: 'categories',
    width: columnsSizes.short_text,
    expandable: true,
  },
  priority: {
    label: 'Приоритет',
    name: 'priority',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
  },
  average_rate: {
    label: 'Средняя оценка',
    name: 'average_rate',
    css: 'text-center',
    ordering: true,
    width: columnsSizes.number,
    component: 'rating',
    transform: (val) => {
      if (val === 0) return val
      if (!val) return '-'
      return val
    },
  },
  phone_number: { label: 'Телефон', name: 'phone_number', width: columnsSizes.phone },
}

export const LEGAL_ENTITY_NOTIFICATIONS_TRANSLATER = {
  id: {
    label: 'Номер',
    name: 'id',
    width: columnsSizes.tiny,
    css: 'text-center',
    ordering: true,
    truncate: true,
  },
  task: {
    label: 'Номер задачи',
    name: 'task',
    width: columnsSizes.short_text,
    css: 'text-center text-truncate',
  },
  created: {
    label: 'Создано',
    name: 'created',
    width: columnsSizes.short_text,
    css: 'text-center',
    transform: (val) => {
      const date = moment(val).format('DD.MM.YYYY HH:mm')
      if (date === 'Invalid date') {
        return null
      }
      return date
    },
    truncate: true,
  },
  description: {
    label: 'Описание',
    name: 'description',
    truncate: true,
    width: '868px',
  },
  status: {
    label: 'Статус',
    name: 'status',
    truncate: true,
    width: columnsSizes.long_text,
  },
}
