import {
  FEEDBACKS_LOADING,
  FEEDBACKS_QUERY_FILTER,
  FEEDBACKS_SUCCESS,
  FEEDBACKS_ERROR,
  FEEDBACKS_SET_PAGE,
  FEEDBACKS_DELETE,
  FEEDBACKS_EDIT,
  LOGOUT,
} from '../constants'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import createInitialState from '../createInitialState'

const initialState = createInitialState()

function extractUser(item) {
  const user = { ...item.user }
  delete user.id
  delete item.user
  return { ...item, ...user }
}

function extractUsers(arr) {
  return arr.map((item) => {
    return extractUser(item)
  })
}

export function feedbacks(state = initialState, action) {
  switch (action.type) {
    case FEEDBACKS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case FEEDBACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: extractUsers(action.payload.results),
        loadIsRequired: false,
        currentPage:
          state.currentPage || getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, 30),
        },
      }
    case FEEDBACKS_EDIT:
      return {
        ...state,
        isLoading: false,
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return extractUser(action.payload)
          }
          return item
        }),
      }
    case FEEDBACKS_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case FEEDBACKS_SET_PAGE:
      return {
        ...state,
        loadIsRequired: true,
        currentPage: action.payload,
      }
    case FEEDBACKS_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        currentPage: 1,
        loadIsRequired: true,
      }
    case FEEDBACKS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
