import {
  CLIENTS_LOADING,
  CLIENTS_SUCCESS,
  CLIENTS_ERROR,
  CLIENTS_EDIT,
  CLIENTS_PAGE_LOADED,
  CLIENTS_DELETE,
  CLIENTS_ADDRESS_CREATE,
  CLIENTS_ADDRESS_EDIT,
  CLIENTS_ADDRESS_DELETE,
  CLIENTS_QUERY_FILTER,
  CLIENTS_DROP_QUERY_FILTER,
  CLIENTS_ORDERING,
  CLIENTS_TOGGLE_COLUMNS,
  CLIENTS_LOADED,
  CLIENTS_RESIZE_COLUMNS,
  CLIENTS_CHANGE_CHECKED,
  CLIENTS_SYCRONIZED,
  LOGOUT,
} from '../constants'
import {
  extractPageNumber,
  getCurrentPage,
  getPagesCount,
  extractProfile,
  convertAddressObj,
} from 'src/utils/formatters'
import { CLIENT } from 'src/utils/keyTranslater'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from '../createInitialState'

const config = {
  success: false,
  columnsSize: {},
  filter: Object.keys(CLIENT),
  checkedList: [],
}

const initialState = createInitialState(config)

export function clients(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
      }
    case CLIENTS_LOADED:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
      }
    case CLIENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        curretPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        items: action.payload.clients.map((client) => {
          return extractProfile(client)
        }),
        pagination: {
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    }
    case CLIENTS_PAGE_LOADED:
      return {
        ...state,
        success: true,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case CLIENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        success: false,
        error: action.payload.error,
      }
    case CLIENTS_EDIT:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        items: state.items.map((client) => {
          if (client.id === action.payload.id) {
            return extractProfile(action.payload)
          }
          return client
        }),
      }
    case CLIENTS_DELETE:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: true,
      }
    case CLIENTS_ADDRESS_CREATE:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        items: state.items.map((client) => {
          if (`${client.id}` === action.payload.id) {
            const updated = client.address || []
            return { ...client, address: updated.concat(convertAddressObj(action.payload.address)) }
          }
          return client
        }),
      }
    case CLIENTS_ADDRESS_EDIT:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        items: state.items.map((client) => {
          if (`${client.id}` === action.payload.id) {
            return {
              ...client,
              address: client.address.map((addr) => {
                if (addr.id === action.payload.address.id) {
                  return convertAddressObj(action.payload.address)
                }
                return addr
              }),
            }
          }
          return client
        }),
      }
    case CLIENTS_ADDRESS_DELETE:
      return {
        ...state,
        isLoading: false,
        success: true,
        items: state.items.map((client) => {
          if (`${client.id}` === action.payload.id) {
            return {
              ...client,
              address: client.address.filter(
                (addr) => `${addr.id}` !== `${action.payload.addressID}`,
              ),
            }
          }
          return client
        }),
      }
    case CLIENTS_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case CLIENTS_QUERY_FILTER:
      return {
        ...state,
        currentPage: null,
        query: action.payload,
        loadIsRequired: true,
      }
    case CLIENTS_DROP_QUERY_FILTER:
      return {
        ...state,
        query: null,
        loadIsRequired: true,
      }
    case CLIENTS_TOGGLE_COLUMNS:
      return {
        ...state,
        filter: action.payload,
      }
    case CLIENTS_RESIZE_COLUMNS:
      return {
        ...state,
        columnsSize: action.payload,
      }
    case CLIENTS_CHANGE_CHECKED:
      return {
        ...state,
        checkedList: action.payload,
      }
    case CLIENTS_SYCRONIZED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
