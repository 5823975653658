function arrChildrenRetrieve(arr) {
  let formatArr = []
  arr.forEach((item) => {
    if (item.childrens.length > 0) {
      let childrens = item.childrens.map((child) => ({ id: child.id, name: child.name }))
      formatArr = [...formatArr, ...childrens]
    } else {
      formatArr = [...formatArr, { id: item.id, name: item.name }]
    }
  })
  return formatArr
}

function findValueInArray(string, arr) {
  if (!string) return ''
  const search = arr.find((item) => item.name === string || item.short_name === string)
  if (!search) return
  return search.id
}

function findIdAttrInTable(ev) {
  let elem = ev.target
  while (elem.tagName !== 'TR') {
    elem = elem.parentElement
    if (elem.tagName === 'TR') {
      return elem.dataset.id
    }
  }
}

function getObjectFieldsInflated(object) {
  let keyValuePairs = []
  if ((object && typeof object !== 'object') || object === null) {
    return keyValuePairs
  }
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const res = getObjectFieldsInflated(value)
      keyValuePairs = [...keyValuePairs, { [key]: res }]
    } else if (Array.isArray(value)) {
      if (value.length) {
        keyValuePairs = [...keyValuePairs, { [key]: value }]
      }
    } else if (value) {
      keyValuePairs = [...keyValuePairs, { [key]: value }]
    }
  })
  return keyValuePairs
}

function getIsSucсessStatus(strStatus) {
  return strStatus.includes('окончен')
}

function getIsRejectStatus(strStatus) {
  return strStatus.includes('отказ') || strStatus.includes('отмен') || false
}

export {
  arrChildrenRetrieve,
  findValueInArray,
  findIdAttrInTable,
  getObjectFieldsInflated,
  getIsSucсessStatus,
  getIsRejectStatus,
}
