import {
  TASKS_LOADING,
  TASKS_SUCCESS,
  TASKS_ERROR,
  TASKS_ADD,
  TASKS_DELETE,
  TASKS_UPDATE,
  TASKS_ORDERING,
  TASKS_TOGGLE_COLUMNS,
  TASKS_PAGE_LOADED,
  TASKS_QUERY_FILTER,
  TASKS_DROP_QUERY_FILTER,
  TASKS_RESIZE_COLUMNS,
  TASKS_CHANGE_CHECKED,
  TASKS_SYNCRONIZED,
  TASKS_FINISH_LOADING,
  TASKS_CONTRACT_SENDING,
  LOGOUT,
  TASKS_CHARGE_UPDATING,
  TASKS_REFRESH,
} from '../constants'
import { TASK } from 'src/utils/keyTranslater'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from '../createInitialState'
import { getUserColumnsSizes } from 'src/utils/localStorageManager'

const config = {
  filter: Object.keys(TASK),
  columnsSize: getUserColumnsSizes()['tasks'] || {},
  checkedList: [],
  contract_sending: false,
  charge_updating: false,
  count: 0,
}

const initialState = createInitialState(config)

export function tasks(state = initialState, action) {
  switch (action.type) {
    case TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.tasks,
        loadIsRequired: false,
        count: action.payload.count,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case TASKS_PAGE_LOADED:
      return {
        ...state,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case TASKS_QUERY_FILTER:
      return {
        ...state,
        currentPage: null,
        query: action.payload,
        loadIsRequired: true,
      }
    case TASKS_DROP_QUERY_FILTER:
      return {
        ...state,
        query: '',
        loadIsRequired: true,
      }
    case TASKS_ADD:
      return {
        ...state,
        loadIsRequired: true,
        count: state.count + 1,
      }
    case TASKS_UPDATE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case TASKS_DELETE:
      return {
        ...state,
        isLoading: false,
        count: state.count - 1,
        items: state.items.filter((item) => `${item.id}` !== action.payload),
        loadIsRequired: false,
      }
    case TASKS_TOGGLE_COLUMNS:
      return {
        ...state,
        filter: action.payload,
      }
    case TASKS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case TASKS_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case TASKS_RESIZE_COLUMNS:
      return {
        ...state,
        columnsSize: action.payload || {},
      }
    case TASKS_CHANGE_CHECKED:
      return {
        ...state,
        checkedList: action.payload,
      }
    case TASKS_SYNCRONIZED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case TASKS_CONTRACT_SENDING:
      return {
        ...state,
        contract_sending: true,
      }
    case TASKS_CHARGE_UPDATING:
      return {
        ...state,
        charge_updating: true,
      }
    case TASKS_FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
        contract_sending: false,
        charge_updating: false,
      }
    case TASKS_REFRESH:
      return {
        ...state,
        items: action.payload.tasks,
        loadIsRequired: false,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        count: action.payload.count,
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
