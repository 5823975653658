import moment from 'moment'

function arrOfCitiesToString(arr) {
  const names = arr.map((item) => item.name)
  return names.join(', ')
}

function arrOfCategoriesToString(arr) {
  let categories = '-'
  if (Array.isArray(arr)) {
    const names = arr.map((item) => item.name)
    categories = names.join(', ')
  }
  return categories
}

function formatDate(date) {
  const stringDate = moment(date).format('YYYY-MM-DD')
  if (stringDate === 'Invalid date') {
    return null
  }
  return stringDate
}

function arrOfCommentsToString(arr) {
  if (arr.length === 0) {
    return '-'
  }
  const strings = arr.map((item) => {
    const str = `${item.author} ${formatDate(item.created)} ${item.text}`
    return str
  })
  return strings.join(', ')
}

function setDefaultValue(arr, name) {
  return arr.find((item) => {
    if (item.name === name) {
      return { label: item.name, value: item.id }
    }
    return { label: 'не выбрано', value: 0 }
  })
}

function extractPageNumber(url) {
  if (!url) return null
  const urlChunk = url.split('?')[1]
  let pageNumber = 1
  if (!urlChunk) {
    return pageNumber
  }
  urlChunk.split('&').forEach((chunk) => {
    const matchChunk = chunk.match('page')
    if (matchChunk) {
      pageNumber = Number(chunk.split('=')[1])
      return
    }
  })
  return pageNumber
}

function getCurrentPage(prev) {
  if (!prev) {
    return 1
  }
  return prev + 1
}

function getPagesCount(totalCount, limit) {
  if (totalCount <= limit) return 1
  return Math.ceil(totalCount / limit)
}

function createArrayFromNumber(number) {
  let arr = []
  if (number > 1) {
    for (let i = 0; i < number; i++) {
      arr.push(i + 1)
    }
  }
  return arr
}

function arrayToQueryString(arr, key) {
  if (Array.isArray(arr)) {
    return arr
      .map((item) => {
        return key + '=' + encodeURIComponent(item.value)
      })
      .join('&')
  }
  return
}

function objToQueryString(obj) {
  return Object.entries(obj)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return arrayToQueryString(value, key)
      } else if (typeof value == 'object' && value.hasOwnProperty('value')) {
        return `${key}=${value.value}`
      }
      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&')
}

function removeEmptyFields(formData, ignoreKeys = []) {
  let flt = {}
  Object.entries(formData).forEach(([key, value]) => {
    if (ignoreKeys.length > 0 && ignoreKeys.includes(key)) {
      flt = { ...flt, [key]: value }
      return
    }
    if (Array.isArray(value) && value.length === 0) {
      return
    }
    if (value === null || value === 'undefined') {
      return
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      flt = { ...flt, [key]: removeEmptyFields(value) }
    } else if (value || value === false) {
      flt = { ...flt, [key]: value }
    }
  })
  return flt
}

function extractProfile(clientObj) {
  let newItem
  if (clientObj.profile) {
    newItem = { ...clientObj, ...clientObj.profile }
  } else {
    newItem = { ...clientObj, address: [], first_name: '-' }
  }
  delete newItem.profile
  return newItem
}

function arrAddressToString(arr) {
  let address = '-'
  if (Array.isArray(arr)) {
    const text = arr.map((item) => item.text)
    address = text.join(' ')
  }
  return address
}

function arrTasksToString(arr) {
  let tasks = '-'
  if (Array.isArray(arr)) {
    const taskText = arr.map(
      (item, index) => `${index + 1}.${item.category}/${item.description}/${item.status}`,
    )
    tasks = taskText.join(' ')
  }
  return tasks
}

function convertAddressObj(obj) {
  if (typeof obj !== 'object') return false
  const text = `${obj.street},${obj.house_number},${obj.block ? obj.block : ''},${
    obj.flat ? obj.flat : ''
  }`
  return { id: obj.id, text }
}

function formatInputDate(date) {
  return moment(date).format('DD.MM.YYYY')
}

function formatBoolean(bool) {
  return bool ? 'Да' : 'Нет'
}

export {
  arrOfCitiesToString,
  formatDate,
  arrOfCommentsToString,
  setDefaultValue,
  extractPageNumber,
  getCurrentPage,
  getPagesCount,
  createArrayFromNumber,
  arrayToQueryString,
  objToQueryString,
  removeEmptyFields,
  arrOfCategoriesToString,
  extractProfile,
  arrAddressToString,
  arrTasksToString,
  convertAddressObj,
  formatInputDate,
  formatBoolean,
}
