const pagination = {
  currentPage: null,
  pagination: {
    prev: null,
    next: null,
    current: null,
    count: null,
  },
}

const defaultConf = {
  ...pagination,
  query: null,
  ordering: '',
}

function createInitialState(config = {}, ignoreDefault = false) {
  let conf = {}

  if (ignoreDefault) {
    conf = { ...config }
  } else {
    conf = { ...defaultConf, ...config }
  }

  let initialState = {
    items: [],
    isLoading: false,
    loadIsRequired: true,
    error: null,
    ...conf,
  }

  return initialState
}

export default createInitialState
