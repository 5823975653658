import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import { vapidKey, firebaseConfig } from './config'

const firebase = initializeApp(firebaseConfig)

export function registerToken() {
  return isSupported().then((res) => {
    if (res) {
      const messaging = getMessaging(firebase)
      return getToken(messaging, { vapidKey: vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken
          } else {
            // Show permission request UI
            throw new Error('No registration token')
            // ...
          }
        })
        .catch((err) => {
          throw err
          // ...
        })
    }
    return Promise.reject(new Error('FCM dont available'))
  })
}

export function onMessageListener() {
  return isSupported().then((res) => {
    if (res) {
      const messaging = getMessaging(firebase)
      return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
          resolve(payload)
        })
      })
    }
    return Promise.reject(new Error('FCM dont available'))
  })
}
