import {
  EMPLOYEES_LOADING,
  EMPLOYEES_SUCCESS,
  EMPLOYEES_ERROR,
  EMPLOYEES_ADD,
  EMPLOYEES_EDIT,
  EMPLOYEES_PAGE_LOADED,
  EMPLOYEES_DELETE,
  EMPLOYEES_QUERY_FILTER,
  EMPLOYEES_ORDERING,
  EMPLOYEES_TOGGLE_COLUMNS,
  EMPLOYEES_RESIZE_COLUMNS,
  EMPLOYEES_CHANGE_CHECKED,
  EMPLOYEES_DROP_QUERY_FILTER,
  EMPLOYEES_ENABLE,
  EMPLOYEES_DISABLE,
  EMPLOYEES_LOADED,
  LOGOUT,
} from '../../constants'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import { EMPLOYEE_TRASLATER } from 'src/utils/keyTranslater'
import roles from 'src/utils/roles'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from 'src/store/createInitialState'

const config = { filter: Object.keys(EMPLOYEE_TRASLATER), checkedList: [], columnsSize: {} }
const initialState = createInitialState(config)

export function employees(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case EMPLOYEES_LOADED:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
      }
    case EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.employee.map((person) => {
          return { ...person, ...person.user }
        }),
        loadIsRequired: false,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case EMPLOYEES_PAGE_LOADED:
      return {
        ...state,
        loadIsRequired: true,
        currentPage: action.payload,
      }
    case EMPLOYEES_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case EMPLOYEES_ADD:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
        items: [],
      }
    case EMPLOYEES_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.map((employee) => {
          if (employee.id === action.payload.id) {
            return {
              ...action.payload,
              ...action.payload.user,
              role: roles.find((role) => action.payload.user.role === role.value).label,
            }
          }
          return employee
        }),
      }
    case EMPLOYEES_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
        pagination: {
          ...state.pagination,
        },
      }
    case EMPLOYEES_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case EMPLOYEES_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        currentPage: null,
        loadIsRequired: true,
      }
    case EMPLOYEES_DROP_QUERY_FILTER:
      return {
        ...state,
        query: null,
        loadIsRequired: true,
      }
    case EMPLOYEES_TOGGLE_COLUMNS:
      return {
        ...state,
        filter: action.payload,
      }
    case EMPLOYEES_RESIZE_COLUMNS:
      return {
        ...state,
        columnsSize: action.payload,
      }
    case EMPLOYEES_CHANGE_CHECKED:
      return {
        ...state,
        checkedList: action.payload,
      }
    case EMPLOYEES_ENABLE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.map((person) => {
          if (`${person.id}` === action.payload.id) {
            return { ...person, is_active: true, isLoading: false }
          }
          return person
        }),
      }
    case EMPLOYEES_DISABLE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.map((person) => {
          if (`${person.id}` === action.payload.id) {
            return { ...person, is_active: false, isLoading: false }
          }
          return person
        }),
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
