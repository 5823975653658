import {
  SUPPORT_CONTACTS_LOADING,
  SUPPORT_CONTACTS_SUCCESS,
  SUPPORT_CONTACTS_ERROR,
  SUPPORT_CONTACTS_ADD,
  SUPPORT_CONTACTS_DELETE,
  SUPPORT_CONTACTS_EDIT,
  LOGOUT,
} from '../constants'

const initialState = {
  items: [],
  checkedList: [],
  isLoading: false,
  loadIsRequired: true,
  error: null,
}

export function supportContacts(state = initialState, action) {
  switch (action.type) {
    case SUPPORT_CONTACTS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SUPPORT_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.map((item) => {
          const name = item.city ? item.city.name : null
          return { ...item, city: name, processed: true }
        }),
        loadIsRequired: false,
      }
    case SUPPORT_CONTACTS_ADD:
    case SUPPORT_CONTACTS_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case SUPPORT_CONTACTS_DELETE:
      return {
        ...state,
        isLoading: false,
        items: state.items.filter((item) => `${item.id}` !== action.payload),
        loadIsRequired: false,
      }
    case SUPPORT_CONTACTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
