export const ACCESS = 'access'
export const REFRESH = 'refresh'
export const ROLE = 'role'
export const ID = 'id'

export const DIRECTOR = 'Директор'
export const ADMINISTRATOR = 'Администратор'
export const OPERATOR = 'Оператор'
export const SUPERADMIN = 'Супер_администратор'
export const LEGAL_ENTITY = 'Юр_лицо'
export const MANAGER = 'Менеджер'

export const COLUMNS_SIZES = 'columns_sizes'

export const PagesLimit = 30

export const WORK_DONE = 'WORK_DONE'

export const TIMEZONE = [
  'KALININGRAD',
  'MOSCOW',
  'KIROV',
  'VOLGOGRAD',
  'ASTRAKHAN',
  'SARATOV',
  'ULYANOVSK',
  'SAMARA',
  'YEKATERINBURG',
  'OMSK',
  'NOVOSIBIRSK',
  'BARNAUL',
  'TOMSK',
  'NOVOKUZNETSK',
  'KRASNOYARSK',
  'IRKUTSK',
  'CHITA',
  'YAKUTSK',
  'KHANDYGA',
  'VLADIVOSTOK',
  'UST_NERA',
  'MAGADAN',
  'SAKHALIN',
  'SREDNEKOLYMSK',
  'KAMCHATKA',
  'ANADYR',
]
