import { ACCESS, REFRESH, ROLE, ID, COLUMNS_SIZES } from './constants'

let observers = []

const setItem = (key, value, shouldNotify = false) => {
  if (value) {
    localStorage.setItem(key, value)
  } else {
    localStorage.removeItem(key)
  }
  shouldNotify && notify()
}

const setAccess = (access_token) => {
  setItem(ACCESS, access_token)
}

const setUser = (access, refresh, role, id) => {
  setItem(ACCESS, access)
  setItem(ROLE, role)
  setItem(REFRESH, refresh, true)
  setItem(ID, id)
}

const removeUserColumnsSizes = () => {
  localStorage.removeItem(COLUMNS_SIZES)
}

const getAccess = () => {
  return localStorage.getItem(ACCESS)
}

const getRefresh = () => {
  return localStorage.getItem(REFRESH)
}

const getRole = () => {
  return localStorage.getItem(ROLE)
}

const getID = () => {
  return localStorage.getItem(ID)
}

const getUserColumnsSizes = () => {
  const savedSizes = localStorage.getItem(COLUMNS_SIZES)
  if (!savedSizes) return {}
  return JSON.parse(savedSizes)
}

const saveUserColumnSizes = (payload) => {
  const prevSizes = getUserColumnsSizes()
  const currenSizes = { ...prevSizes, ...payload }
  const json = JSON.stringify(currenSizes)
  localStorage.setItem(COLUMNS_SIZES, json)
}

const isLoggedIn = () => {
  return !!getRefresh()
}

const subscribe = (observer) => {
  observers.push(observer)
}

const unsubscribe = (_observer) => {
  observers = observers.filter((observer) => observer !== _observer)
}

const notify = () => {
  observers.forEach((observer) => {
    observer(isLoggedIn())
  })
}

const getUserId = () => {
  const token = getAccess()
  try {
    const base64Payload = token.split('.')[1]
    const payload = Buffer.from(base64Payload, 'base64')
    const userInfo = JSON.parse(payload.toString())
    return userInfo['user_id'] || null
  } catch (e) {
    return null
  }
}

export {
  setUser,
  setAccess,
  getAccess,
  getRefresh,
  isLoggedIn,
  subscribe,
  unsubscribe,
  getRole,
  getUserId,
  getID,
  getUserColumnsSizes,
  saveUserColumnSizes,
  removeUserColumnsSizes,
}
