import {
  LEGAL_ENTITY_LOADING,
  LEGAL_ENTITY_SUCCESS,
  LEGAL_ENTITY_ERROR,
  LEGAL_ENTITY_EDIT,
  LEGAL_ENTITY_PAGE_LOADED,
  LEGAL_ENTITY_DELETE,
  LEGAL_ENTITY_ADDRESS_CREATE,
  LEGAL_ENTITY_ADDRESS_EDIT,
  LEGAL_ENTITY_ADDRESS_DELETE,
  LEGAL_ENTITY_QUERY_FILTER,
  LEGAL_ENTITY_DROP_QUERY_FILTER,
  LEGAL_ENTITY_ORDERING,
  LEGAL_ENTITY_TOGGLE_COLUMNS,
  LEGAL_ENTITY_LOADED,
  LEGAL_ENTITY_RESIZE_COLUMNS,
  LEGAL_ENTITY_CHANGE_CHECKED,
  LEGAL_ENTITY_SYCRONIZED,
  LOGOUT,
} from '../constants'
import {
  extractPageNumber,
  getCurrentPage,
  getPagesCount,
  extractProfile,
  convertAddressObj,
} from 'src/utils/formatters'
import { LEGAL_ENTITY } from 'src/utils/keyTranslater'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from '../createInitialState'

const config = {
  success: false,
  columnsSize: {},
  filter: Object.keys(LEGAL_ENTITY),
  checkedList: [],
}

const initialState = createInitialState(config)

export function legalEntity(state = initialState, action) {
  switch (action.type) {
    case LEGAL_ENTITY_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
      }
    case LEGAL_ENTITY_LOADED:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
      }
    case LEGAL_ENTITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        curretPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        items: action.payload.legal_entities.map((client) => {
          return extractProfile(client)
        }),
        pagination: {
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    }
    case LEGAL_ENTITY_PAGE_LOADED:
      return {
        ...state,
        success: true,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        success: false,
        error: action.payload.error,
      }
    case LEGAL_ENTITY_EDIT:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        items: state.items.map((client) => {
          if (client.id === action.payload.id) {
            return extractProfile(action.payload)
          }
          return client
        }),
      }
    case LEGAL_ENTITY_DELETE:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_ADDRESS_CREATE:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        items: state.items.map((client) => {
          if (`${client.id}` === action.payload.id) {
            const updated = client.address || []
            return { ...client, address: updated.concat(convertAddressObj(action.payload.address)) }
          }
          return client
        }),
      }
    case LEGAL_ENTITY_ADDRESS_EDIT:
      return {
        ...state,
        isLoading: false,
        success: true,
        loadIsRequired: false,
        items: state.items.map((client) => {
          if (`${client.id}` === action.payload.id) {
            return {
              ...client,
              address: client.address.map((addr) => {
                if (addr.id === action.payload.address.id) {
                  return convertAddressObj(action.payload.address)
                }
                return addr
              }),
            }
          }
          return client
        }),
      }
    case LEGAL_ENTITY_ADDRESS_DELETE:
      return {
        ...state,
        isLoading: false,
        success: true,
        items: state.items.map((client) => {
          if (`${client.id}` === action.payload.id) {
            return {
              ...client,
              address: client.address.filter(
                (addr) => `${addr.id}` !== `${action.payload.addressID}`,
              ),
            }
          }
          return client
        }),
      }
    case LEGAL_ENTITY_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_QUERY_FILTER:
      return {
        ...state,
        currentPage: null,
        query: action.payload,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_DROP_QUERY_FILTER:
      return {
        ...state,
        query: null,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_TOGGLE_COLUMNS:
      return {
        ...state,
        filter: action.payload,
      }
    case LEGAL_ENTITY_RESIZE_COLUMNS:
      return {
        ...state,
        columnsSize: action.payload,
      }
    case LEGAL_ENTITY_CHANGE_CHECKED:
      return {
        ...state,
        checkedList: action.payload,
      }
    case LEGAL_ENTITY_SYCRONIZED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
