import axios from 'axios'
import { getAccess, getRefresh, setAccess, setUser } from '../utils/localStorageManager'
import { HOST, REFRESH } from './apiRoutes'

const authRequest = axios.create({
  baseURL: HOST,
  responseType: 'json',
})

authRequest.interceptors.request.use(
  (config) => {
    const token = getAccess()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    console.log('interceptor', error)
    return Promise.reject(error)
  },
)

let isRefreshing = false
let failedQueue = []

function refresh() {
  const refresh = getRefresh()
  if (!refresh) {
    return Promise.reject(new Error('refresh token is required'))
  }
  return axios.post(
    `${HOST}${REFRESH}`,
    { refresh: refresh },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

authRequest.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.message === 'Network Error') {
      return Promise.reject(error)
    }

    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers.Authorization = 'Bearer ' + token
            return axios(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(function (resolve, reject) {
        refresh()
          .then((response) => {
            setAccess(response.data.access)
            originalRequest.headers.Authorization = 'Bearer ' + response.data.access
            processQueue(null, response.data.access)
            resolve(axios(originalRequest))
          })
          .catch((err) => {
            processQueue(err, null)
            setUser()
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }

    return Promise.reject(error)
  },
)

export default authRequest

export { refresh }
