import {
  DIAGNOSTIC_TARIFFS_LOADING,
  DIAGNOSTIC_TARIFFS_DROP_QUERY_FILTER,
  DIAGNOSTIC_TARIFFS_ERROR,
  DIAGNOSTIC_TARIFFS_SUCCESS,
  DIAGNOSTIC_TARIFFS_ADD,
  DIAGNOSTIC_TARIFFS_EDIT,
  DIAGNOSTIC_TARIFFS_DELETE,
  DIAGNOSTIC_TARIFFS_QUERY_FILTER,
  DIAGNOSTIC_TARIFFS_ORDERING,
  DIAGNOSTIC_TARIFFS_LOADED,
} from 'src/store/constants'

const initialState = {
  items: [],
  isLoading: false,
  error: null,
  query: null,
  loadIsRequired: true,
  ordering: '',
}

export function diagnosticTariffs(state = initialState, action) {
  switch (action.type) {
    case DIAGNOSTIC_TARIFFS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case DIAGNOSTIC_TARIFFS_LOADED:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: state.loadIsRequired || true,
      }
    case DIAGNOSTIC_TARIFFS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload,
      }
    case DIAGNOSTIC_TARIFFS_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case DIAGNOSTIC_TARIFFS_ADD:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: [
          ...state.items,
          {
            ...action.payload,
            city: action.payload.city.map((city) => city.name),
            category: action.payload.category.map((category) => category.name),
          },
        ],
      }
    case DIAGNOSTIC_TARIFFS_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...action.payload,
              city: action.payload.city.map((city) => city.name),
              category: action.payload.category.map((category) => category.name),
            }
          }
          return item
        }),
      }
    case DIAGNOSTIC_TARIFFS_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.filter((item) => `${item.id}` !== action.payload.id),
      }
    case DIAGNOSTIC_TARIFFS_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case DIAGNOSTIC_TARIFFS_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case DIAGNOSTIC_TARIFFS_DROP_QUERY_FILTER:
      return {
        ...state,
        ordering: '',
        loadIsRequired: true,
      }
    default:
      return state
  }
}
