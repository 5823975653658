import { getIsRejectStatus, getIsSucсessStatus } from 'src/utils/helpers'
import {
  HANDBOOK_STATUSES_LOADING,
  HANDBOOK_STATUSES_SUCCESS,
  HANDBOOK_STATUSES_ERROR,
  LOGOUT,
} from '../../constants'

const initialState = {
  items: [],
  isLoading: false,
  loadIsRequired: true,
  error: null,
}

export function handbookStatuses(state = initialState, action) {
  switch (action.type) {
    case HANDBOOK_STATUSES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case HANDBOOK_STATUSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload.map((item) => {
          if (getIsSucсessStatus(item.text)) {
            return { ...item, color: 'success' }
          } else if (getIsRejectStatus(item.text)) {
            return { ...item, color: 'danger' }
          }
          return { ...item, color: 'warning' }
        }),
      }
    case HANDBOOK_STATUSES_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
