import { combineReducers } from 'redux'
import { changeState } from './changeState'
import { routerReducer } from 'react-router-redux'
import { tasks } from './tasks'
import { legalEntityTasks } from './legalEntity/tasks'
import { selectable } from './selectable'
import { alert } from './alert'
import { cities } from './handbook/cities'
import { user } from './user'
import { clients } from './clients'
import { legalEntity } from './legalEntity'
import { masters } from './handbook/masters'
import { efficiency } from './eficiency'
import { crmTasksReport } from './reports/crmTasksReport'
import { paymentRules } from './handbook/paymentRules'
import { startPage } from './startPage'
import { handbookStatuses } from './handbook/handbookStatuses'
import { handbookCategories } from './handbook/handbookCategories'
import { employees } from './handbook/employyes'
import { diagnosticTariffs } from './handbook/diagnosticTariffs'
import { tariffs } from './handbook/tariffs'
import { feedbacks } from './feedbacks'
import { masterReport } from './reports/masterReport'
import { financialReport } from './reports/financialReport'
import { operatorReport } from './reports/operatorReport'
import { workEfficiencyReport } from './reports/workEfficiencyReport'
import { supportContacts } from './supportContacts'
import { mastersOnwork, operatorsOnwork } from './onWork'
import { legalEntityProfile } from './legalEntity/profile'
import { legalEntityResponsiblePerson } from './legalEntity/responsiblePerson'

export default combineReducers({
  startPage,
  tasks,
  legalEntityTasks,
  selectable,
  changeState,
  alert,
  cities,
  user,
  clients,
  legalEntity,
  masters,
  efficiency,
  crmTasksReport,
  paymentRules,
  handbookStatuses,
  handbookCategories,
  employees,
  diagnosticTariffs,
  tariffs,
  feedbacks,
  masterReport,
  financialReport,
  operatorReport,
  workEfficiencyReport,
  supportContacts,
  mastersOnwork,
  operatorsOnwork: operatorsOnwork,
  legalEntityProfile,
  legalEntityResponsiblePerson,
  routing: routerReducer,
})
