import {
  MASTERS_LOADING,
  MASTERS_SUCCESS,
  MASTERS_ERROR,
  MASTERS_ADD,
  MASTERS_DELETE,
  MASTERS_EDIT,
  MASTERS_DROP_QUERY_FILTER,
  MASTERS_QUERY_FILTER,
  MASTERS_TOGGLE_COLUMNS,
  MASTERS_PAGE_LOADED,
  MASTERS_ORDERING,
  MASTERS_LOADED,
  MASTERS_CHANGE_CHECKED,
  LOGOUT,
  MASTERS_RESIZE_COLUMNS,
  MASTERS_DISABLE,
  MASTERS_ENABLE,
} from '../../constants'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import { MASTER } from 'src/utils/keyTranslater'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from 'src/store/createInitialState'

const config = { filter: Object.keys(MASTER), checkedList: [], columnsSize: {} }
const initialState = createInitialState(config)

function extractUsers(arr) {
  return arr.map((item) => ({ ...item, ...item.user }))
}

export function masters(state = initialState, action) {
  switch (action.type) {
    case MASTERS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case MASTERS_LOADED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: extractUsers(action.payload.masters),
        loadIsRequired: false,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case MASTERS_PAGE_LOADED:
      return {
        ...state,
        loadIsRequired: true,
        currentPage: action.payload,
      }
    case MASTERS_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case MASTERS_ADD:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
        items: [],
      }
    case MASTERS_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: state.items.length > 1 ? false : true,
        items:
          state.items.length > 1
            ? state.items.map((master) => {
                if (master.id === action.payload.id) {
                  return { ...action.payload, ...action.payload.user }
                }
                return master
              })
            : [],
      }
    case MASTERS_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
        pagination: {
          ...state.pagination,
        },
      }
    case MASTERS_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case MASTERS_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        currentPage: null,
        loadIsRequired: true,
      }
    case MASTERS_DROP_QUERY_FILTER:
      return {
        ...state,
        query: null,
        loadIsRequired: true,
      }
    case MASTERS_ENABLE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.map((person) => {
          if (`${person.id}` === action.payload.id) {
            return { ...person, is_active: true, isLoading: false }
          }
          return person
        }),
      }
    case MASTERS_DISABLE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.map((person) => {
          if (`${person.id}` === action.payload.id) {
            return { ...person, is_active: false, isLoading: false }
          }
          return person
        }),
      }
    case MASTERS_TOGGLE_COLUMNS:
      return {
        ...state,
        filter: action.payload,
      }
    case MASTERS_RESIZE_COLUMNS:
      return {
        ...state,
        columnsSize: action.payload,
      }
    case MASTERS_CHANGE_CHECKED:
      return {
        ...state,
        checkedList: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
