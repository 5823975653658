const roles = [
  { value: 3, label: 'Оператор' },
  { value: 9, label: 'Менеджер' },
  { value: 4, label: 'Директор' },
  { value: 6, label: 'Администратор' },
]

export default roles

const rolesList = [
  { value: 1, label: 'Клиент' },
  { value: 2, label: 'Мастер' },
  { value: 3, label: 'Опратор' },
  { value: 9, label: 'Менеджер' },
  { value: 4, label: 'Директор' },
  { value: 5, label: 'Партнёр' },
  { value: 6, label: 'Администратор' },
  { value: 7, label: 'Супер администратор' },
]

export { rolesList, roles }
