import {
  PAYMENT_RULES_LOADING,
  PAYMENT_RULES_ERROR,
  PAYMENT_RULES_SUCCESS,
  PAYMENT_RULES_QUERY_FILTER,
  PAYMENT_RULES_ADD,
  PAYMENT_RULES_DELETE,
  PAYMENT_RULES_EDIT,
  PAYMENT_RULES_ORDERING,
  PAYMENT_RULES_DROP_QUERY_FILTER,
  PAYMENT_RULES_SYNCRONIZED,
  PAYMENT_RULES_PAGE_LOADED,
  LOGOUT,
} from '../../constants'
import { PAYMENT_RULES } from 'src/utils/keyTranslater'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from 'src/store/createInitialState'

const config = { filter: Object.keys(PAYMENT_RULES), columnsSize: {}, checkedList: [] }
const initialState = createInitialState(config)

export function paymentRules(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_RULES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case PAYMENT_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload.results,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case PAYMENT_RULES_PAGE_LOADED:
      return {
        ...state,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case PAYMENT_RULES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case PAYMENT_RULES_ADD:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case PAYMENT_RULES_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case PAYMENT_RULES_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.filter((item) => `${item.id}` !== action.payload.id),
      }
    case PAYMENT_RULES_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case PAYMENT_RULES_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case PAYMENT_RULES_DROP_QUERY_FILTER:
      return {
        ...state,
        query: null,
        loadIsRequired: true,
      }
    case PAYMENT_RULES_SYNCRONIZED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
