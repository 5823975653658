import { ALERT_SHOW, ALERT_CLOSE } from '../constants'

const showAlert = (success, heading, msg = []) => {
  const payload = { success, heading, msg }
  return { type: ALERT_SHOW, payload }
}

const closeAlert = () => {
  return { type: ALERT_CLOSE }
}

export { showAlert, closeAlert }
