import {
  MASTERS_ONWORK_LOADING,
  MASTERS_ONWORK_SUCCESS,
  MASTERS_ONWORK_ERROR,
  MASTERS_ONWORK_QUERY_FILTER,
  MASTERS_ONWORK_PAGE_LOADED,
  MASTERS_ONWORK_ORDERING,
  MASTERS_ONWORK_DROP_QUERY_FILTER,
  OPERATORS_ONWORK_LOADING,
  OPERATORS_ONWORK_SUCCESS,
  OPERATORS_ONWORK_QUERY_FILTER,
  OPERATORS_ONWORK_ERROR,
  OPERATORS_ONWORK_IS_SHOW,
  OPERATORS_ONWORK_PAGE_LOADED,
  OPERATORS_ONWORK_ORDERING,
  OPERATORS_ONWORK_DROP_QUERY_FILTER,
  LOGIN,
  LOGOUT,
} from '../constants'
import createInitialState from '../createInitialState'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import { PagesLimit } from 'src/utils/constants'
import { MASTERS_ONWORK_TRANSLATER, OPERATORS_ONWORK_TRANSLATER } from 'src/utils/keyTranslater'

const initialState = createInitialState({
  columnsSize: {},
  filter: Object.keys(MASTERS_ONWORK_TRANSLATER),
})

export function mastersOnwork(state = initialState, action) {
  switch (action.type) {
    case MASTERS_ONWORK_LOADING:
      return {
        ...state,
        isLoading: true,
        loadIsRequired: false,
      }
    case MASTERS_ONWORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload.masters,
        error: null,
        count: action.payload.count,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case MASTERS_ONWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: [],
        error: action.payload.error,
      }
    case MASTERS_ONWORK_QUERY_FILTER:
      return {
        ...state,
        currentPage: null,
        query: action.payload,
        loadIsRequired: true,
      }
    case MASTERS_ONWORK_PAGE_LOADED:
      return {
        ...state,
        success: true,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case MASTERS_ONWORK_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case MASTERS_ONWORK_DROP_QUERY_FILTER:
      return {
        ...state,
        ordering: null,
        query: null,
        currentPage: null,
        loadIsRequired: true,
      }
    case LOGIN:
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

const operatorInitialState = createInitialState({
  columnsSize: {},
  filter: Object.keys(OPERATORS_ONWORK_TRANSLATER),
  isShow: true,
})

export function operatorsOnwork(state = operatorInitialState, action) {
  switch (action.type) {
    case OPERATORS_ONWORK_LOADING:
      return {
        ...state,
        isLoading: true,
        loadIsRequired: false,
      }
    case OPERATORS_ONWORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload.employee,
        error: null,
        count: action.payload.count,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case OPERATORS_ONWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: [],
        error: action.payload.error,
      }
    case OPERATORS_ONWORK_QUERY_FILTER:
      return {
        ...state,
        currentPage: null,
        query: action.payload,
        loadIsRequired: true,
      }
    case OPERATORS_ONWORK_IS_SHOW:
      return {
        ...state,
        isShow: action.payload.isShow,
      }
    case OPERATORS_ONWORK_PAGE_LOADED:
      return {
        ...state,
        success: true,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case OPERATORS_ONWORK_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case OPERATORS_ONWORK_DROP_QUERY_FILTER:
      return {
        ...state,
        query: null,
        currentPage: null,
        ordering: null,
        loadIsRequired: true,
      }
    case LOGIN:
    case LOGOUT:
      return operatorInitialState
    default:
      return state
  }
}
