import {
  SELECTABLE_CATEGORIES,
  SELECTABLE_LOADING,
  SELECTABLE_ERROR,
  LOGOUT,
  LOGIN,
} from '../constants'

const initialState = {
  isLoading: true,
  error: null,
  category: [],
  cities: [],
  success: false,
}

export function selectable(state = initialState, action) {
  switch (action.type) {
    case SELECTABLE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SELECTABLE_CATEGORIES:
      return {
        ...state,
        [action.key]: action.payload,
        isLoading: false,
        success: true,
      }
    case SELECTABLE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case LOGIN:
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
