import {
  LEGAL_ENTITY_TASKS_LOADING,
  LEGAL_ENTITY_TASKS_SUCCESS,
  LEGAL_ENTITY_TASKS_ERROR,
  LEGAL_ENTITY_TASKS_ADD,
  LEGAL_ENTITY_TASKS_DELETE,
  LEGAL_ENTITY_TASKS_UPDATE,
  LEGAL_ENTITY_TASKS_ORDERING,
  LEGAL_ENTITY_TASKS_TOGGLE_COLUMNS,
  LEGAL_ENTITY_TASKS_PAGE_LOADED,
  TASKS_QUERY_FILTER,
  LEGAL_ENTITY_TASKS_DROP_QUERY_FILTER,
  LEGAL_ENTITY_TASKS_RESIZE_COLUMNS,
  LEGAL_ENTITY_TASKS_CHANGE_CHECKED,
  LEGAL_ENTITY_TASKS_SYNCRONIZED,
  LEGAL_ENTITY_TASKS_FINISH_LOADING,
  LEGAL_ENTITY_TASKS_CONTRACT_SENDING,
  LOGOUT,
  LEGAL_ENTITY_TASKS_CHARGE_UPDATING,
  LEGAL_ENTITY_TASKS_REFRESH,
} from '../../constants'
import { ENTITY_TASK } from 'src/utils/keyTranslater'
import { extractPageNumber, getCurrentPage, getPagesCount } from 'src/utils/formatters'
import { PagesLimit } from 'src/utils/constants'
import createInitialState from '../../createInitialState'
import { getUserColumnsSizes } from 'src/utils/localStorageManager'

const config = {
  filter: Object.keys(ENTITY_TASK),
  columnsSize: getUserColumnsSizes()['legalEntityTasks'] || {},
  checkedList: [],
  contract_sending: false,
  charge_updating: false,
  count: 0,
}

const initialState = createInitialState(config)

export function legalEntityTasks(state = initialState, action) {
  switch (action.type) {
    case LEGAL_ENTITY_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case LEGAL_ENTITY_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.results,
        loadIsRequired: false,
        count: action.payload.count,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case LEGAL_ENTITY_TASKS_PAGE_LOADED:
      return {
        ...state,
        currentPage: action.payload,
        loadIsRequired: true,
      }
    case TASKS_QUERY_FILTER:
      return {
        ...state,
        currentPage: null,
        query: action.payload,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_TASKS_DROP_QUERY_FILTER:
      return {
        ...state,
        query: '',
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_TASKS_ADD:
      return {
        ...state,
        loadIsRequired: true,
        count: state.count + 1,
      }
    case LEGAL_ENTITY_TASKS_UPDATE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_TASKS_DELETE:
      return {
        ...state,
        isLoading: false,
        count: state.count - 1,
        items: state.items.filter((item) => `${item.id}` !== action.payload),
        loadIsRequired: false,
      }
    case LEGAL_ENTITY_TASKS_TOGGLE_COLUMNS:
      return {
        ...state,
        filter: action.payload,
      }
    case LEGAL_ENTITY_TASKS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case LEGAL_ENTITY_TASKS_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_TASKS_RESIZE_COLUMNS:
      return {
        ...state,
        columnsSize: action.payload || {},
      }
    case LEGAL_ENTITY_TASKS_CHANGE_CHECKED:
      return {
        ...state,
        checkedList: action.payload,
      }
    case LEGAL_ENTITY_TASKS_SYNCRONIZED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_TASKS_CONTRACT_SENDING:
      return {
        ...state,
        contract_sending: true,
      }
    case LEGAL_ENTITY_TASKS_CHARGE_UPDATING:
      return {
        ...state,
        charge_updating: true,
      }
    case LEGAL_ENTITY_TASKS_FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
        contract_sending: false,
        charge_updating: false,
      }
    case LEGAL_ENTITY_TASKS_REFRESH:
      return {
        ...state,
        items: action.payload.results,
        loadIsRequired: false,
        currentPage: getCurrentPage(extractPageNumber(action.payload.previous)),
        count: action.payload.count,
        pagination: {
          ...state.pagination,
          prev: extractPageNumber(action.payload.previous),
          next: extractPageNumber(action.payload.next),
          current: getCurrentPage(extractPageNumber(action.payload.previous)),
          count: getPagesCount(action.payload.count, PagesLimit),
        },
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
