const columnsSizes = {
  tiny: 68,
  date: 102,
  short_text: 128,
  long_text: 288,
  medium_text: 200,
  phone: 112,
  number: 96,
}

export default columnsSizes
