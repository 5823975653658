import {
  LEGAL_ENTITY_RESPONSIBLE_PERSON_EDIT,
  LEGAL_ENTITY_RESPONSIBLE_PERSON_ERROR,
  LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADING,
  LEGAL_ENTITY_RESPONSIBLE_PERSON_LOAD_IS_REQUIRED,
  LEGAL_ENTITY_RESPONSIBLE_PERSON_SUCCESS,
  LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADED,
  LOGOUT,
} from 'src/store/constants'

const initialData = {
  isLoading: false,
  loadIsRequired: true,
  data: null,
  error: null,
}

export function legalEntityResponsiblePerson(state = initialData, action) {
  switch (action.type) {
    case LEGAL_ENTITY_RESPONSIBLE_PERSON_LOAD_IS_REQUIRED:
      return {
        ...state,
        loadIsRequired: true,
      }
    case LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADING:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: true,
      }
    case LEGAL_ENTITY_RESPONSIBLE_PERSON_SUCCESS:
    case LEGAL_ENTITY_RESPONSIBLE_PERSON_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        data: action.payload,
      }
    case LEGAL_ENTITY_RESPONSIBLE_PERSON_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADED:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: false,
      }
    case LOGOUT:
      return initialData
    default:
      return state
  }
}
