import {
  CRM_TASKS_REPORT_LOADING,
  CRM_TASKS_REPORT_SUCCESS,
  CRM_TASKS_REPORT_ERROR,
  CRM_TASKS_REPORT_QUERY_FILTER,
  CRM_TASKS_REPORT_DROP_QUERY_FILTER,
  LOGOUT,
} from '../../constants'
import moment from 'moment'

const initialState = {
  isLoading: false,
  report: [],
  loadIsRequired: false,
  query: null,
  error: null,
  start_date: moment().startOf('month').format('YYYY-MM-DD'),
  stop_date: moment().format('YYYY-MM-DD'),
}

function extractHeadCol(statistics) {
  let fakeCol = {
    city: 'Категория/Город',
    statistics: [],
  }
  statistics.forEach((item) => {
    let childrens = []
    if (item.childrens) {
      item.childrens.forEach((child) => {
        childrens = [...childrens, { name: child.name }]
      })
    }
    fakeCol = {
      ...fakeCol,
      statistics: [
        ...fakeCol.statistics,
        { name: item.name, childrens: childrens.length > 0 ? childrens : null },
      ],
    }
  })
  return fakeCol
}

export function crmTasksReport(state = initialState, action) {
  switch (action.type) {
    case CRM_TASKS_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case CRM_TASKS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        report: [extractHeadCol(action.payload[0].statistics), ...action.payload],
        start_date: action.payload[0].start_date,
        stop_date: action.payload[0].stop_date,
      }
    case CRM_TASKS_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case CRM_TASKS_REPORT_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case CRM_TASKS_REPORT_DROP_QUERY_FILTER:
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
