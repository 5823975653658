import {
  HANDBOOK_CATEGORIES_ADD,
  HANDBOOK_CATEGORIES_LOADING,
  HANDBOOK_CATEGORIES_SUCCESS,
  HANDBOOK_CATEGORIES_ERROR,
  HANDBOOK_CATEGORIES_EDIT,
  HANDBOOK_CATEGORIES_DELETE,
  HANDBOOK_CATEGORIES_ORDERING,
  HANDBOOK_CATEGORIES_QUERY_FILTER,
  LOGOUT,
} from '../../constants'

const initialState = {
  loadIsRequired: true,
  isLoading: true,
  items: [],
  error: null,
  ordering: '',
  query: null,
}

export function handbookCategories(state = initialState, action) {
  switch (action.type) {
    case HANDBOOK_CATEGORIES_LOADING:
      return {
        ...state,
        isLoading: true,
        loadIsRequired: false,
      }
    case HANDBOOK_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload.map((cat) => ({
          ...cat,
          city: cat.city.map((city) => city.name),
          city_to_show: cat.city_to_show.map((city) => city.name),
        })),
      }
    case HANDBOOK_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case HANDBOOK_CATEGORIES_ADD:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case HANDBOOK_CATEGORIES_EDIT:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case HANDBOOK_CATEGORIES_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case HANDBOOK_CATEGORIES_ORDERING:
      return {
        ...state,
        ordering: action.payload,
        loadIsRequired: true,
      }
    case HANDBOOK_CATEGORIES_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
