import {
  START_PAGE_LOADING,
  START_PAGE_SUCCESSS,
  START_PAGE_ERROR,
  START_PAGE_QUERY_FILTER,
  LOGIN,
  LOGOUT,
} from '../constants'
import moment from 'moment'
import createInitialState from '../createInitialState'

const config = {
  query: {
    'start-date': moment().startOf('month').format('YYYY-MM-DD'),
    'end-date': moment().format('YYYY-MM-DD'),
  },
}

const initialState = createInitialState(config, true)

export function startPage(state = initialState, action) {
  switch (action.type) {
    case START_PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
        loadIsRequired: false,
      }
    case START_PAGE_SUCCESSS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload,
        error: null,
      }
    case START_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: [],
        error: action.payload.error,
      }
    case START_PAGE_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case LOGIN:
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
