const vapidKey =
  'BGEbFr65sd5Bpx01PjBnJIY2QtdIhow2DR6EcUtg-hRy_fY0zCZpHcfZuie2L3BVoG7bQW7vTcLJqCnbIGWmOyI'
const firebaseConfig = {
  apiKey: 'AIzaSyC9csrTUta0UNnk_yIaPQ-Y25zAiIzxykQ',
  authDomain: 'domspas-master.firebaseapp.com',
  projectId: 'domspas-master',
  storageBucket: 'domspas-master.appspot.com',
  messagingSenderId: '1026279945571',
  appId: '1:1026279945571:web:21b65c20da63e47a6f8f56',
}

export { vapidKey, firebaseConfig }
