import axios from 'axios'
import {
  HOST,
  AUTH,
  MASTERS_CHOICES,
  MASTERS,
  ENABLE,
  DISABLE,
  TASKS,
  TASK_API,
  UPLOAD_DOCUMENT,
  CATEGORY,
  CITIESLIST,
  CLIENTS,
  ADDRESS,
  AVATAR,
  CRM_CITIES,
  CRM_TASKS_REPORTS,
  OPERATORS_CHOICES,
  ENTITIES_CHOICES,
  PAYMENT_RULES,
  START_PAGE,
  CRM_EFFICIENCY,
  HANDBOOK_STATUSES,
  HANDBOOK_CATEGORIES,
  EMPLOYEES,
  DIAGNOSTIC_TARIFFS,
  TARIFFS,
  PAYMENT_RULE_CHOICES,
  TARIFF_CHOICES,
  XLSX,
  OPERATOR_ONLINE,
  LOGS,
  CRM_LOGS,
  SETTINGS_SUPPORT,
  GET,
  UPDATE,
  SETTINGS_MASTER,
  RESET,
  FEEDBACK,
  CRM_REPORTS_MASTER,
  FINANCIAL_REPORT,
  CRM_REPORTS_OPERATOR,
  CRM_REPORTS_WORK_EFFICIENCY,
  XLSX_DAY,
  LOOKING_FOR_MASTER,
  FIREBASE_DEVICES_REGISTER,
  DEVICES,
  NOTIFICATIONS,
  SEND_CONTRACT,
  TRANSACTIONS,
  SET_PROCESSED,
  CHARGE_INCOME,
  VISA_READY,
  VISA_ACCEPTED,
  VISA_PAID,
  AVAILABLE_OPERATORS,
  DOWNLOAD_CONTRACT,
  MASTERS_ONWORK,
  OPERATORS_ONWORK,
  ALL_CITIES,
  LEGAL_ENTITY_TASKS,
  LEGAL_CRM_LEGAL_ENTITY,
  LEGAL_ENTITY_NOTIFICATIONS,
  LEGAL_ENTITY_PROFILE,
  LEGAL_ENTITY_RESPONSIBLE_PERSON,
  LEGAL_ENTITY,
  LEGAL_ENTITY_VALIDATE,
} from './apiRoutes'
import authRequest from './authRequest'
// import pageRequest from './pageRequest'
import { objToQueryString, removeEmptyFields } from 'src/utils/formatters'

const instance = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
})

function requestWithParams(url, params, resType = null) {
  return authRequest.get(url, {
    responseType: resType || 'json',
    params,
    paramsSerializer: () => objToQueryString(removeEmptyFields(params)),
  })
}

function createApi(url) {
  return {
    load: (params) => requestWithParams(url, params),
    post: (data) => authRequest.post(url, data),
    retrieve: (id) => authRequest.get(`${url}/${id}`),
    put: (id, data) => authRequest.put(`${url}/${id}`, data),
    delete: (id) => authRequest.delete(`${url}/${id}`),
  }
}

function getLegalEntityNotifications(params) {
  return requestWithParams(LEGAL_ENTITY_NOTIFICATIONS, params)
}

function creaLegalEntityApi(url) {
  return {
    retrieve: () => authRequest.get(url),
    put: (data) => authRequest.put(url, data),
  }
}

function Login(data) {
  return instance.post(AUTH, data)
}

function LoadMasters(arrCategories = null) {
  if (arrCategories) {
    return authRequest.get(`${MASTERS_CHOICES}?${arrCategories}`)
  }
  return authRequest.get(MASTERS_CHOICES)
}

function LoadCategory() {
  return authRequest.get(CATEGORY)
}

function LoadCities() {
  return authRequest.get(CRM_CITIES)
}

function TaskLog(id) {
  return authRequest.get(`${TASKS}/${id}${LOGS}`)
}

function UpdateTaskStatus(id, data, apiUrl) {
  return authRequest.post(`${TASKS}/${id}${apiUrl}`, data)
}

function SetStatusLookingForMaster(id) {
  return authRequest.get(`${TASKS}/${id}${LOOKING_FOR_MASTER}`)
}

function GetCitiesList() {
  return authRequest.get(CITIESLIST)
}

function CreateCity(data) {
  return authRequest.post(CITIESLIST, data)
}

function UpdateCity(id, data) {
  return authRequest.put(`${CITIESLIST}/${id}`, data)
}

function CityRetrieve(id) {
  return authRequest.get(`${CITIESLIST}/${id}`)
}

function DeleteCity(id) {
  return authRequest.delete(`${CITIESLIST}/${id}`)
}

function AddressRetrieve(id) {
  return authRequest.get(`${ADDRESS}/${id}`)
}

function UpdateAddress(id, data) {
  return authRequest.put(`${ADDRESS}/${id}`, data)
}

function DeleteAddress(id) {
  return authRequest.delete(`${ADDRESS}/${id}`)
}

function CreateAddress(data) {
  return authRequest.post(ADDRESS, data)
}

function Efficiency(params) {
  return requestWithParams(CRM_EFFICIENCY, params)
}

function GetCrmCities() {
  return authRequest.get(CRM_CITIES)
}

function GetOperatorsChoices(query = null) {
  if (query && query.length > 0) {
    return authRequest.get(`${OPERATORS_CHOICES}?${query}`)
  }
  return authRequest.get(OPERATORS_CHOICES)
}

function GetEntitiesChoices(query = null) {
  if (query && query.length > 0) {
    return authRequest.get(`${ENTITIES_CHOICES}?${query}`)
  }
  return authRequest.get(ENTITIES_CHOICES)
}

function LoadStartPage(params = null) {
  return requestWithParams(START_PAGE, params)
}

function GetHandbookStatuses() {
  return authRequest.get(HANDBOOK_STATUSES)
}

const handbookCategoriesApi = createApi(HANDBOOK_CATEGORIES)
const handbookEmployeesApi = {
  ...createApi(EMPLOYEES),
  disable: (id) => authRequest.get(`${EMPLOYEES}/${id}/off`),
  enable: (id) => authRequest.get(`${EMPLOYEES}/${id}/on`),
  export: (params) => requestWithParams(`${EMPLOYEES}${XLSX}`, params, 'blob'),
}
const mastersApi = {
  ...createApi(MASTERS),
  disable: (id) => authRequest.get(`${MASTERS}/${id}${DISABLE}`),
  enable: (id) => authRequest.get(`${MASTERS}/${id}${ENABLE}`),
  avatar: (id, data) => {
    const formData = new FormData()
    formData.append('photo', data.photo)
    return authRequest.post(`${MASTERS}/${id}${AVATAR}`, formData)
  },
  export: (params) => requestWithParams(`${MASTERS}${XLSX}`, params, 'blob'),
}
const diagnosticTariffsApi = createApi(DIAGNOSTIC_TARIFFS)
const tariffsApi = createApi(TARIFFS)
const paymentRulesApi = createApi(PAYMENT_RULES)
const clientsApi = {
  ...createApi(CLIENTS),
  post: null,
  export: (params) => requestWithParams(`${CLIENTS}${XLSX}`, params, 'blob'),
}
const legalEntityApi = {
  ...createApi(LEGAL_CRM_LEGAL_ENTITY),
  post: null,
  export: (params) => requestWithParams(`${LEGAL_CRM_LEGAL_ENTITY}${XLSX}`, params, 'blob'),
}

const tasksApi = {
  ...createApi(TASKS),
  export: (params) => requestWithParams(`${TASKS}${XLSX}`, params, 'blob'),
  chargeIncome: (id, payload) => authRequest.patch(`${TASKS}/${id}${CHARGE_INCOME}`, payload),
  downloadContract: (payload) =>
    authRequest.post(`${TASKS}${DOWNLOAD_CONTRACT}`, payload, { responseType: 'blob' }),
  uploadDocument: (id, payload) =>
    authRequest.post(`${TASK_API}/${id}${UPLOAD_DOCUMENT}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  setVisaReady: (id) => authRequest.get(`${TASKS}/${id}${VISA_READY}`),
  setVisaAccepted: (id) => authRequest.get(`${TASKS}/${id}${VISA_ACCEPTED}`),
  setVisaPaid: (id) => authRequest.get(`${TASKS}/${id}${VISA_PAID}`),
}

const entityTasksApi = {
  ...createApi(LEGAL_ENTITY_TASKS),
  export: (params) => requestWithParams(`${LEGAL_ENTITY_TASKS}${XLSX}`, params, 'blob'),
  chargeIncome: (id, payload) =>
    authRequest.patch(`${LEGAL_ENTITY_TASKS}/${id}${CHARGE_INCOME}`, payload),
  downloadDocument: (doc_id) =>
    authRequest.get(`${LEGAL_CRM_LEGAL_ENTITY}/documents/${doc_id}`, {
      responseType: 'blob',
    }),
  deleteDocument: (doc_id) => authRequest.get(`${TASK_API}/remove-document/${doc_id}`),
}

function PaymentRuleChoices() {
  return authRequest.get(PAYMENT_RULE_CHOICES)
}

function TariffChoices() {
  return authRequest.get(TARIFF_CHOICES)
}

function OperatorGetStatus() {
  return authRequest.get(OPERATOR_ONLINE)
}

function OperatorUpdateStatus(data) {
  return authRequest.post(OPERATOR_ONLINE, data)
}

function GetLogs(params) {
  return authRequest.get(CRM_LOGS, {
    params,
    paramsSerializer: () => objToQueryString(removeEmptyFields(params)),
  })
}

const supportContactsApi = createApi(SETTINGS_SUPPORT)

function GetSettingsMaster() {
  return authRequest.get(`${SETTINGS_MASTER}${GET}`)
}

function UpdateSettingsMaster(data) {
  return authRequest.put(`${SETTINGS_MASTER}${UPDATE}`, data)
}

function ResetSettingsMaster() {
  return authRequest.get(`${SETTINGS_MASTER}${RESET}`)
}

function GetFeedbacks(params = null, id = null) {
  if (id) {
    return authRequest.get(`${FEEDBACK}/${id}`)
  }
  return requestWithParams(FEEDBACK, params)
}

function updateFeedback(id, data) {
  return authRequest.put(`${FEEDBACK}/${id}`, data)
}

function deleteFeedback(id) {
  return authRequest.delete(`${FEEDBACK}/${id}`)
}

function GetMasterReports(params, resType = false) {
  if (resType) return requestWithParams(`${CRM_REPORTS_MASTER}${XLSX}`, params, 'blob')
  return requestWithParams(CRM_REPORTS_MASTER, params)
}

function GetFinancialReports(params, resType = false) {
  if (resType) return requestWithParams(`${FINANCIAL_REPORT}${XLSX}`, params, 'blob')
  return requestWithParams(FINANCIAL_REPORT, params)
}

function GetOperatorReports(params, resType = false) {
  if (resType) return requestWithParams(`${CRM_REPORTS_OPERATOR}${XLSX}`, params, 'blob')
  return requestWithParams(CRM_REPORTS_OPERATOR, params)
}

function GetWorkEfficiencyReports(params, resType = false) {
  if (resType) return requestWithParams(`${CRM_REPORTS_WORK_EFFICIENCY}${XLSX}`, params, 'blob')
  return requestWithParams(CRM_REPORTS_WORK_EFFICIENCY, params)
}

function GetCrmTaskReport(params) {
  return requestWithParams(CRM_TASKS_REPORTS, params)
}

function exportTaskCountReport(params, by_day = false) {
  if (by_day) {
    return requestWithParams(`${CRM_TASKS_REPORTS}${XLSX_DAY}`, params, 'blob')
  }
  return requestWithParams(`${CRM_TASKS_REPORTS}${XLSX}`, params, 'blob')
}

function loadOperatorsChoices(params) {
  return requestWithParams(OPERATORS_CHOICES, params)
}

function loadMasterChoices(params) {
  return requestWithParams(MASTERS_CHOICES, params)
}

function registerDevice(token) {
  const data = {
    registration_id: token,
    active: true,
  }
  return authRequest.post(FIREBASE_DEVICES_REGISTER, data)
}

function updateStatusDevice(token, active = false) {
  const data = {
    registration_id: token,
    active: active,
  }
  return authRequest.patch(`${DEVICES}/${token}`, data)
}

function unregisterDevice(token) {
  return authRequest.delete(`${DEVICES}/${token}`)
}

function getNotifications(params) {
  return requestWithParams(NOTIFICATIONS, params)
}

function markNotifications(id) {
  return authRequest.get(`${NOTIFICATIONS}/${id}${SET_PROCESSED}`)
}

function sendContract(id) {
  return authRequest.get(`${TASKS}/${id}${SEND_CONTRACT}`)
}

function getTransactions(params) {
  return requestWithParams(TRANSACTIONS, params)
}

function retrieveTransaction(id) {
  return authRequest.get(`${TRANSACTIONS}/${id}`)
}

function getOperatorsOnwork(params) {
  return requestWithParams(OPERATORS_ONWORK, params)
}

function getMastersOnwork(params) {
  return requestWithParams(MASTERS_ONWORK, params)
}

function getAavailableOperators() {
  return authRequest.get(AVAILABLE_OPERATORS)
}

function getAllCities() {
  return instance.get(ALL_CITIES)
}

function legalEntityRegister(data) {
  return authRequest.post(LEGAL_ENTITY, data)
}

function legalEntityValidate(params) {
  return instance.get(LEGAL_ENTITY_VALIDATE, { params })
}

function legalEntityCrmProfileApi(id) {
  return authRequest.get(`${LEGAL_CRM_LEGAL_ENTITY}/${id}`)
}

function legalEntityCrmProfileUpdateApi(id, data) {
  return authRequest.put(`${LEGAL_CRM_LEGAL_ENTITY}/${id}`, data)
}

const legalEntityProfileApi = creaLegalEntityApi(LEGAL_ENTITY_PROFILE)
const legalEntityResponsiblePersonApi = creaLegalEntityApi(LEGAL_ENTITY_RESPONSIBLE_PERSON)

export {
  Login,
  LoadMasters,
  LoadCategory,
  LoadCities,
  GetCitiesList,
  CreateCity,
  UpdateCity,
  CityRetrieve,
  DeleteCity,
  CreateAddress,
  AddressRetrieve,
  UpdateAddress,
  DeleteAddress,
  Efficiency,
  GetCrmCities,
  TaskLog,
  GetCrmTaskReport,
  GetOperatorsChoices,
  GetEntitiesChoices,
  LoadStartPage,
  GetHandbookStatuses,
  PaymentRuleChoices,
  TariffChoices,
  handbookCategoriesApi,
  handbookEmployeesApi,
  mastersApi,
  diagnosticTariffsApi,
  tariffsApi,
  paymentRulesApi,
  clientsApi,
  tasksApi,
  entityTasksApi,
  UpdateTaskStatus,
  OperatorGetStatus,
  OperatorUpdateStatus,
  GetLogs,
  supportContactsApi,
  GetSettingsMaster,
  UpdateSettingsMaster,
  ResetSettingsMaster,
  GetFeedbacks,
  GetMasterReports,
  GetFinancialReports,
  GetOperatorReports,
  loadOperatorsChoices,
  loadMasterChoices,
  GetWorkEfficiencyReports,
  exportTaskCountReport,
  SetStatusLookingForMaster,
  registerDevice,
  updateStatusDevice,
  unregisterDevice,
  getNotifications,
  sendContract,
  getTransactions,
  retrieveTransaction,
  markNotifications,
  updateFeedback,
  deleteFeedback,
  getAavailableOperators,
  getOperatorsOnwork,
  getMastersOnwork,
  getAllCities,
  getLegalEntityNotifications,
  legalEntityProfileApi,
  legalEntityResponsiblePersonApi,
  legalEntityRegister,
  legalEntityApi,
  legalEntityValidate,
  legalEntityCrmProfileApi,
  legalEntityCrmProfileUpdateApi,
}
