import { ALERT_SHOW, ALERT_CLOSE, LOGOUT } from '../constants'

const initialState = {
  color: 'light',
  heading: '',
  msg: [],
  show: false,
}

export function alert(state = initialState, action) {
  switch (action.type) {
    case ALERT_SHOW:
      return {
        ...state,
        color: action.payload.success ? 'success' : 'danger',
        heading: action.payload.heading,
        msg: action.payload.msg || [],
        show: true,
      }
    case LOGOUT:
    case ALERT_CLOSE:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
