import {
  EFFICIENCY_LOADING,
  EFFICIENCY_SUCCESS,
  EFFICIENCY_ERROR,
  EFFICIENCY_QUERY_FILTER,
  LOGOUT,
} from '../constants'
import moment from 'moment'
import createInitialState from '../createInitialState'

const config = {
  query: {
    'start-date': moment().startOf('month').format('YYYY-MM-DD'),
    'end-date': moment().format('YYYY-MM-DD'),
  },
}

const initialState = createInitialState(config, true)

export function efficiency(state = initialState, action) {
  switch (action.type) {
    case EFFICIENCY_LOADING:
      return {
        ...state,
        isLoading: true,
        loadIsRequired: false,
      }
    case EFFICIENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        error: null,
      }
    case EFFICIENCY_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case EFFICIENCY_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
