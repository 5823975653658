import React, { Component } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import { isLoggedIn, subscribe } from './utils/localStorageManager'
import store, { history } from './store/index'
import { logoutAction } from './store/actions/user'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const EntityRegister = React.lazy(() => import('./views/pages/entityRegister/EntityRegister'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ValidateEntity = React.lazy(() => import('./views/pages/entityRegister/validateEntity'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { isLogged: isLoggedIn() }
    this.listener = (newLoggedState) => {
      return this.setState({ isLogged: newLoggedState })
    }
  }

  componentDidMount() {
    subscribe(this.listener)
  }

  componentDidUpdate() {
    if (!this.state.isLogged) {
      return store.dispatch(logoutAction())
    }
  }

  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/validate-entity"
              render={(props) => <ValidateEntity title="" {...props} />}
            />
            <Route
              exact
              path="/entity-register"
              name="Entity Register Page"
              render={(props) => <EntityRegister {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home">
              {this.state.isLogged ? (
                <DefaultLayout />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )}
            </Route>
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
