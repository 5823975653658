export const TASKS_LOADING = 'TASKS_LOADING'
export const TASKS_SUCCESS = 'TASKS_SUCCESS'
export const TASKS_ERROR = 'TASKS_ERROR'
export const TASKS_ADD = 'TASKS_ADD'
export const TASKS_DELETE = 'TASKS_DELETE'
export const TASKS_UPDATE = 'TASKS_UPDATE'
export const TASKS_TOGGLE_COLUMNS = 'TASKS_TOGGLE_COLUMNS'
export const TASKS_ORDERING = 'TASKS_ORDERING'
export const TASKS_PAGE_LOADED = 'TASKS_PAGE_LOADED'
export const TASKS_QUERY_FILTER = 'TASKS_QUERY_FILTER'
export const TASKS_DROP_QUERY_FILTER = 'TASKS_DROP_QUERY_FILTER'
export const TASKS_RESIZE_COLUMNS = 'TASKS_RESIZE_COLUMNS'
export const TASKS_CHANGE_CHECKED = 'TASKS_CHANGE_CHECKED'
export const TASKS_SYNCRONIZED = 'TASKS_SYNCRONIZED'
export const TASKS_FINISH_LOADING = 'TASKS_FINISH_LOADING'
export const TASKS_CONTRACT_SENDING = 'TASKS_CONTRACT_SENDING'
export const TASKS_CHARGE_UPDATING = 'TASKS_CHARGE_UPDATING'
export const TASKS_REFRESH = 'TASKS_REFRESH'

export const SELECTABLE_LOADING = 'SELECTABLE_LOADING'
export const SELECTABLE_ERROR = 'SELECTABLE_ERROR'
export const SELECTABLE_CATEGORIES = 'SELECTABLE_CATEGORIES'

export const ALERT_SHOW = 'ALERT_SHOW'
export const ALERT_CLOSE = 'ALERT_CLOSE'

export const CITIES_LOADING = 'CITIES_LOADING'
export const CITIES_SUCCESS = 'CITIES_SUCCESS'
export const CITIES_ERROR = 'CITIES_ERROR'
export const CITIES_ADD = 'CITIES_ADD'
export const CITIES_EDIT = 'CITIES_EDIT'
export const CITIES_DELETE = 'CITIES_DELETE'

export const LOGIN = 'LOGIN'
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const DIASBLE_TOOLTIPS = 'DISABLE_TOOLTIPS'
export const USER_AVAILABLE_CITIES = 'USER_AVAILABLE_CITIES'
export const LEGAL_ENTITY_REGISTER = 'LEGAL_ENTITY_REGISTER'
export const LEGAL_ENTITY_VALIDATE = 'LEGAL_ENTITY_VALIDATE'

export const CLIENTS_LOADING = 'CLIENTS_LOADING'
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS'
export const CLIENTS_ERROR = 'CLIENTS_ERROR'
export const CLIENTS_EDIT = 'CLIENTS_EDIT'
export const CLIENTS_PAGE_LOADED = 'CLIENTS_PAGE_LOADED'
export const CLIENTS_DELETE = 'CLIENTS_DELETE'
export const CLIENTS_ADDRESS_CREATE = 'CLIENTS_ADDRESS_CREATE'
export const CLIENTS_ADDRESS_EDIT = 'CLIENTS_ADDRESS_EDIT'
export const CLIENTS_ADDRESS_DELETE = 'CLIENTS_ADDRESS_DELETE'
export const CLIENTS_QUERY_FILTER = 'CLIENTS_QUERY_FILTER'
export const CLIENTS_DROP_QUERY_FILTER = 'CLIENTS_DROP_QUERY_FILTER'
export const CLIENTS_ORDERING = 'CLIENTS_ORDERING'
export const CLIENTS_TOGGLE_COLUMNS = 'CLIENTS_TOGGLE_COLUMNS'
export const CLIENTS_LOADED = 'CLIENTS_LOADED'
export const CLIENTS_RESIZE_COLUMNS = 'CLIENTS_RESIZE_COLUMNS'
export const CLIENTS_CHANGE_CHECKED = 'CLIENTS_CHANGE_CHECKED'
export const CLIENTS_SYCRONIZED = 'CLIENTS_SYNCRONIZED'

export const LEGAL_ENTITY_LOADING = 'LEGAL_ENTITY_LOADING'
export const LEGAL_ENTITY_SUCCESS = 'LEGAL_ENTITY_SUCCESS'
export const LEGAL_ENTITY_ERROR = 'LEGAL_ENTITY_ERROR'
export const LEGAL_ENTITY_EDIT = 'LEGAL_ENTITY_EDIT'
export const LEGAL_ENTITY_PAGE_LOADED = 'LEGAL_ENTITY_PAGE_LOADED'
export const LEGAL_ENTITY_DELETE = 'LEGAL_ENTITY_DELETE'
export const LEGAL_ENTITY_ADDRESS_CREATE = 'LEGAL_ENTITY_ADDRESS_CREATE'
export const LEGAL_ENTITY_ADDRESS_EDIT = 'LEGAL_ENTITY_ADDRESS_EDIT'
export const LEGAL_ENTITY_ADDRESS_DELETE = 'LEGAL_ENTITY_ADDRESS_DELETE'
export const LEGAL_ENTITY_QUERY_FILTER = 'LEGAL_ENTITY_QUERY_FILTER'
export const LEGAL_ENTITY_DROP_QUERY_FILTER = 'LEGAL_ENTITY_DROP_QUERY_FILTER'
export const LEGAL_ENTITY_ORDERING = 'LEGAL_ENTITY_ORDERING'
export const LEGAL_ENTITY_TOGGLE_COLUMNS = 'LEGAL_ENTITY_TOGGLE_COLUMNS'
export const LEGAL_ENTITY_LOADED = 'LEGAL_ENTITY_LOADED'
export const LEGAL_ENTITY_RESIZE_COLUMNS = 'LEGAL_ENTITY_RESIZE_COLUMNS'
export const LEGAL_ENTITY_CHANGE_CHECKED = 'LEGAL_ENTITY_CHANGE_CHECKED'
export const LEGAL_ENTITY_SYCRONIZED = 'LEGAL_ENTITY_SYNCRONIZED'

export const MASTERS_LOADING = 'MASTERS_LOADING'
export const MASTERS_SUCCESS = 'MASTERS_SUCCESS'
export const MASTERS_ERROR = 'MASTERS_ERROR'
export const MASTERS_EDIT = 'MASTERS_EDIT'
export const MASTERS_ADD = 'MASTERS_ADD'
export const MASTERS_PAGE_LOADED = 'MASTERS_PAGE_LOADED'
export const MASTERS_DELETE = 'MASTERS_DELETE'
export const MASTERS_QUERY_FILTER = 'MASTERS_QUERY_FILTER'
export const MASTERS_DROP_QUERY_FILTER = 'MASTERS_DROP_QUERY_FILTER'
export const MASTERS_ORDERING = 'MASTERS_ORDERING'
export const MASTERS_TOGGLE_COLUMNS = 'MASTERS_TOGGLE_COLUMNS'
export const MASTERS_LOADED = 'MASTERS_LOADED'
export const MASTERS_LOAD_ISREQUIRED = 'MASTERS_LOAD_ISREQUIRED'
export const MASTERS_RESIZE_COLUMNS = 'MASTERS_RESIZE_COLUMNS'
export const MASTERS_CHANGE_CHECKED = 'MASTERS_CHANGE_CHECKED'
export const MASTERS_ENABLE = 'MASTERS_ENABLE'
export const MASTERS_DISABLE = 'MASTERS_DISABLE'

export const EFFICIENCY_LOADING = 'EFFICIENCY_LOADING'
export const EFFICIENCY_SUCCESS = 'EFFICIENCY_SUCCESS'
export const EFFICIENCY_ERROR = 'EFFICIENCY_ERROR'
export const EFFICIENCY_QUERY_FILTER = 'EFFICIENCY_QUERY_FILTER'

export const PAYMENT_RULES_LOADING = 'PAYMENT_RULES_LOADING'
export const PAYMENT_RULES_LOADED = 'PAYMENT_RULES_LOADED'
export const PAYMENT_RULES_ERROR = 'PAYMENT_RULES_ERROR'
export const PAYMENT_RULES_SUCCESS = 'PAYMENT_RULES_SUCCESS'
export const PAYMENT_RULES_ADD = 'PAYMENT_RULES_ADD'
export const PAYMENT_RULES_EDIT = 'PAYMENT_RULES_EDIT'
export const PAYMENT_RULES_DELETE = 'PAYMENT_RULES_DELETE'
export const PAYMENT_RULES_ORDERING = 'PAYMENT_RULES_ORDERING'
export const PAYMENT_RULES_QUERY_FILTER = 'PAYMENT_RULES_QUERY_FILTER'
export const PAYMENT_RULES_DROP_QUERY_FILTER = 'PAYMENT_RULES_DROP_QUERY_FILTER'
export const PAYMENT_RULES_PAGE_LOADED = 'PAYMENT_RULES_PAGE_LOADED'
export const PAYMENT_RULES_SYNCRONIZED = 'PAYMENT_RULES_SYNCRONIZED'

export const START_PAGE_LOADING = 'START_PAGE_LOADING'
export const START_PAGE_SUCCESSS = 'START_PAGE_SUCCESS'
export const START_PAGE_ERROR = 'START_PAGE_ERROR'
export const START_PAGE_QUERY_FILTER = 'START_PAGE_QUERY_FILTER'

export const HANDBOOK_STATUSES_LOADING = 'HANDBOOK_STATUSES_LOADING'
export const HANDBOOK_STATUSES_SUCCESS = 'HANDBOOK_STATUSES_SUCCESS'
export const HANDBOOK_STATUSES_ERROR = 'HANDBOOK_STATUSES_ERROR'

export const HANDBOOK_CATEGORIES_LOADING = 'HANDBOOK_CATEGORIES_LOADING'
export const HANDBOOK_CATEGORIES_SUCCESS = 'HANDBOOK_CATEGORIES_SUCCESS'
export const HANDBOOK_CATEGORIES_ERROR = 'HANDBOOK_CATEGORIES_ERROR'
export const HANDBOOK_CATEGORIES_EDIT = 'HANDBOOK_CATEGORIES_EDIT'
export const HANDBOOK_CATEGORIES_ADD = 'HANDBOOK_CATEGORIES_ADD'
export const HANDBOOK_CATEGORIES_DELETE = 'HANDBOOK_CATEGORIES_DELETE'
export const HANDBOOK_CATEGORIES_ORDERING = 'HANDBOOK_CATEGORIES_ORDERING'
export const HANDBOOK_CATEGORIES_QUERY_FILTER = 'HANDBOOK_CATEGORIES_QUERY_FILTER'

export const EMPLOYEES_LOADING = 'EMPLOYEES_LOADING'
export const EMPLOYEES_SUCCESS = 'EMPLOYEES_SUCCESS'
export const EMPLOYEES_ERROR = 'EMPLOYEES_ERROR'
export const EMPLOYEES_ADD = 'EMPLOYEES_ADD'
export const EMPLOYEES_EDIT = 'EMPLOYEES_EDIT'
export const EMPLOYEES_PAGE_LOADED = 'EMPLOYEES_PAGE_LOADED'
export const EMPLOYEES_DELETE = 'EMPLOYEES_DELETE'
export const EMPLOYEES_QUERY_FILTER = 'EMPLOYEES_QUERY_FILTER'
export const EMPLOYEES_ORDERING = 'EMPLOYEES_ORDERING'
export const EMPLOYEES_TOGGLE_COLUMNS = 'EMPLOYEES_TOGGLE_COLUMNS'
export const EMPLOYEES_RESIZE_COLUMNS = 'EMPLOYEES_RESIZE_COLUMNS'
export const EMPLOYEES_CHANGE_CHECKED = 'EMPLOYEES_CHANGE_CHECKED'
export const EMPLOYEES_DROP_QUERY_FILTER = 'EMPLOYEES_DROP_QUERY_FILTER'
export const EMPLOYEES_ENABLE = 'EMPLOYEES_ENABLE'
export const EMPLOYEES_DISABLE = 'EMPLOYEES_DISABLE'
export const EMPLOYEES_LOADED = 'EMPLOYEES_LOADED'

export const DIAGNOSTIC_TARIFFS_LOADING = 'DIAGNOSTIC_TARIFFS_LOADING'
export const DIAGNOSTIC_TARIFFS_LOADED = 'DIAGNOSTIC_TARIFFS_LOADED'
export const DIAGNOSTIC_TARIFFS_ERROR = 'DIAGNOSTIC_TARIFFS_ERROR'
export const DIAGNOSTIC_TARIFFS_SUCCESS = 'DIAGNOSTIC_TARIFFS_SUCCESS'
export const DIAGNOSTIC_TARIFFS_ADD = 'DIAGNOSTIC_TARIFFS_ADD'
export const DIAGNOSTIC_TARIFFS_EDIT = 'DIAGNOSTIC_TARIFFS_EDIT'
export const DIAGNOSTIC_TARIFFS_DELETE = 'DIAGNOSTIC_TARIFFS_DELETE'
export const DIAGNOSTIC_TARIFFS_ORDERING = 'DIAGNOSTIC_TARIFFS_ORDERING'
export const DIAGNOSTIC_TARIFFS_QUERY_FILTER = 'DIAGNOSTIC_TARIFFS_QUERY_FILTER'
export const DIAGNOSTIC_TARIFFS_DROP_QUERY_FILTER = 'DIAGNOSTIC_TARIFFS_DROP_QUERY_FILTER'

export const TARIFFS_LOADING = 'TARIFFS_LOADING'
export const TARIFFS_LOADED = 'TARIFFS_LOADED'
export const TARIFFS_ERROR = 'TARIFFS_ERROR'
export const TARIFFS_SUCCESS = 'TARIFFS_SUCCESS'
export const TARIFFS_ADD = 'TARIFFS_ADD'
export const TARIFFS_EDIT = 'TARIFFS_EDIT'
export const TARIFFS_DELETE = 'TARIFFS_DELETE'
export const TARIFFS_ORDERING = 'TARIFFS_ORDERING'
export const TARIFFS_QUERY_FILTER = 'TARIFFS_QUERY_FILTER'
export const TARIFFS_DROP_QUERY_FILTER = 'TARIFFS_DROP_QUERY_FILTER'

export const FEEDBACKS_LOADING = 'FEEDBACKS_LOADING'
export const FEEDBACKS_SUCCESS = 'FEEDBACKS_SUCCESS'
export const FEEDBACKS_QUERY_FILTER = 'FEEDBACKS_QUERY_FILTER'
export const FEEDBACKS_SET_PAGE = 'FEEDBACKS_SET_PAGE'
export const FEEDBACKS_ERROR = 'FEEDBACKS_ERROR'
export const FEEDBACKS_EDIT = 'FEEDBACKS_EDIT'
export const FEEDBACKS_DELETE = 'FEEDBACKS_DELETE'

export const MASTER_REPORT_LOADING = 'MASTER_REPORT_LOADING'
export const MASTER_REPORT_SUCCESS = 'MASTER_REPORT_SUCCESS'
export const MASTER_REPORT_QUERY_FILTER = 'MASTER_REPORT_QUERY_FILTER'
export const MASTER_REPORT_ERROR = 'MASTER_REPORT_ERROR'
export const MASTER_REPORT_DROP_QUERY_FILTER = 'MASTER_REPORT_DROP_QUERY_FILTER'

export const FINANCIAL_REPORT_LOADING = 'FINANCIAL_REPORT_LOADING'
export const FINANCIAL_REPORT_SUCCESS = 'FINANCIAL_REPORT_SUCCESS'
export const FINANCIAL_REPORT_QUERY_FILTER = 'FINANCIAL_REPORT_QUERY_FILTER'
export const FINANCIAL_REPORT_ERROR = 'FINANCIAL_REPORT_ERROR'
export const FINANCIAL_REPORT_DROP_QUERY_FILTER = 'FINANCIAL_REPORT_DROP_QUERY_FILTER'

export const OPERATOR_REPORT_LOADING = 'OPERATOR_REPORT_LOADING'
export const OPERATOR_REPORT_SUCCESS = 'OPERATOR_REPORT_SUCCESS'
export const OPERATOR_REPORT_QUERY_FILTER = 'OPERATOR_REPORT_QUERY_FILTER'
export const OPERATOR_REPORT_ERROR = 'OPERATOR_REPORT_ERROR'
export const OPERATOR_REPORT_DROP_QUERY_FILTER = 'OPERATOR_REPORT_DROP_QUERY_FILTER'

export const WORK_EFFICIENCY_REPORT_LOADING = 'WORK_EFFICIENCY_REPORT_LOADING'
export const WORK_EFFICIENCY_REPORT_SUCCESS = 'WORK_EFFICIENCY_REPORT_SUCCESS'
export const WORK_EFFICIENCY_REPORT_QUERY_FILTER = 'WORK_EFFICIENCY_REPORT_QUERY_FILTER'
export const WORK_EFFICIENCY_REPORT_ERROR = 'WORK_EFFICIENCY_REPORT_ERROR'
export const WORK_EFFICIENCY_REPORT_DROP_QUERY_FILTER = 'WORK_EFFICIENCY_REPORT_DROP_QUERY_FILTER'

export const CRM_TASKS_REPORT_LOADING = 'CRM_TASKS_REPORT_LOADING'
export const CRM_TASKS_REPORT_SUCCESS = 'CRM_TASKS_REPORT_SUCCESS'
export const CRM_TASKS_REPORT_ERROR = 'CRM_TASKS_REPORT_ERROR'
export const CRM_TASKS_REPORT_QUERY_FILTER = 'CRM_TASKS_REPORT_QUERY_FILTER'
export const CRM_TASKS_REPORT_DROP_QUERY_FILTER = 'CRM_TASKS_REPORT_DROP_QUERY_FILTER'

export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING'
export const TRANSACTIONS_SUCCESS = 'TRANSACTIONS_SUCCESS'
export const TRANSACTIONS_ERROR = 'TRANSACTONS_ERROR'
export const TRANSACTIONS_ORDERING = 'TRANSACTIONS_ORDERING'
export const TRANSACTIONS_PAGE_LOADED = 'TRANSACTIONS_PAGE_LOADED'
export const TRANSACTIONS_QUERY_FILTER = 'TRANSACTIONS_QUERY_FILTER'
export const TRANSACTIONS_FINISH_LOADING = 'TRANSACTIONS_FINISH_LOADING'

export const ONWORK_STATISTICS_LOADING = 'ONWORK_STATISTICS_LOADING'
export const ONWORK_STATISTICS_SUCCESSS = 'ONWORK_STATISTICS_SUCCESS'
export const ONWORK_STATISTICS_ERROR = 'ONWORK_STATISTICS_ERROR'
export const ONWORK_STATISTICS_QUERY_FILTER = 'ONWORK_STATISTICS_QUERY_FILTER'

export const SUPPORT_CONTACTS_LOADING = 'SUPPORT_CONTACTS_LOADING'
export const SUPPORT_CONTACTS_SUCCESS = 'SUPPORT_CONTACTS_SUCCESS'
export const SUPPORT_CONTACTS_ERROR = 'SUPPORT_CONTACTS_ERROR'
export const SUPPORT_CONTACTS_ADD = 'SUPPORT_CONTACTS_ADD'
export const SUPPORT_CONTACTS_EDIT = 'SUPPORT_CONTACTS_EDIT'
export const SUPPORT_CONTACTS_DELETE = 'SUPPORT_CONTACTS_DELETE'

export const LOADING = 'LOADING'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const PAGE_LOADED = 'PAGE_LOADED'
export const EDIT = 'EDIT'
export const ADD = 'ADD'
export const LOADED = 'LOADED'
export const RESIZE_COLUMNS = 'RESIZE_COLUMNS'
export const DROP_QUERY_FILTER = 'DROP_QUERY_FILTER'
export const CHANGE_CHECKED = 'CHANGE_CHECKED'
export const ORDERING = 'ORDERING'
export const QUERY_FILTER = 'QUERY_FILTER'
export const ITEM_LOADING = 'ITEM_LOADING'
export const USER_STATUS = 'USER_STATUS'
export const USER_STATUS_CHANGING = 'USER_STATUS_CHANGING'
export const NOTIFICATION_PERMISSION = 'NOTIFICATION_PERMISSION'
export const REGISTER_FIREBASE_TOKEN = 'REGISTER_FRIEBASE_TOKEN'
export const REGISTER_DEVICE = 'REGISTER_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const DEVICE_STATUS_IN_PROGRESS = 'DEVICE_STATUS_IN_PROGRESS'
export const UNREGISTER_DEVICE = 'UNREGISTER_DEVICE'

export const MASTERS_ONWORK_LOADING = 'MASTERS_ONWORK_LOADING'
export const MASTERS_ONWORK_SUCCESS = 'MASTERS_ONWORK_SUCCESS'
export const MASTERS_ONWORK_ERROR = 'MASTERS_ONWORK_ERROR'
export const MASTERS_ONWORK_QUERY_FILTER = 'MASTERS_ONWORK_QUERY_FILTER'
export const MASTERS_ONWORK_PAGE_LOADED = 'MASTERS_ONWORK_PAGE_LOADED'
export const MASTERS_ONWORK_ORDERING = 'MASTERS_ONWORK_ORDERING'
export const MASTERS_ONWORK_DROP_QUERY_FILTER = 'MASTERS_ONWORK_DROP_QUERY_FILTER'

export const OPERATORS_ONWORK_LOADING = 'OPERATORS_ONWORK_LOADING'
export const OPERATORS_ONWORK_SUCCESS = 'OPERATORS_ONWORK_SUCCESS'
export const OPERATORS_ONWORK_ERROR = 'OPERATORS_ONWORK_ERROR'
export const OPERATORS_ONWORK_QUERY_FILTER = 'OPERATORS_ONWORK_QUERY_FILTER'
export const OPERATORS_ONWORK_IS_SHOW = 'OPERATORS_ONWORK_IS_SHOW'
export const OPERATORS_ONWORK_PAGE_LOADED = 'OPERATORS_ONWORK_PAGE_LOADED'
export const OPERATORS_ONWORK_ORDERING = 'OPERATORS_ONWORK_ORDERING'
export const OPERATORS_ONWORK_DROP_QUERY_FILTER = 'OPERATORS_ONWORK_DROP_QUERY_FILTER'

export const LEGAL_ENTITY_TASKS_LOADING = 'LEGAL_ENTITY_TASKS_LOADING'
export const LEGAL_ENTITY_TASKS_SUCCESS = 'LEGAL_ENTITY_TASKS_SUCCESS'
export const LEGAL_ENTITY_TASKS_ERROR = 'LEGAL_ENTITY_TASKS_ERROR'
export const LEGAL_ENTITY_TASKS_ADD = 'LEGAL_ENTITY_TASKS_ADD'
export const LEGAL_ENTITY_TASKS_DELETE = 'LEGAL_ENTITY_TASKS_DELETE'
export const LEGAL_ENTITY_TASKS_UPDATE = 'LEGAL_ENTITY_TASKS_UPDATE'
export const LEGAL_ENTITY_TASKS_TOGGLE_COLUMNS = 'LEGAL_ENTITY_TASKS_TOGGLE_COLUMNS'
export const LEGAL_ENTITY_TASKS_ORDERING = 'LEGAL_ENTITY_TASKS_ORDERING'
export const LEGAL_ENTITY_TASKS_PAGE_LOADED = 'LEGAL_ENTITY_TASKS_PAGE_LOADED'
export const LEGAL_ENTITY_TASKS_QUERY_FILTER = 'LEGAL_ENTITY_TASKS_QUERY_FILTER'
export const LEGAL_ENTITY_TASKS_DROP_QUERY_FILTER = 'LEGAL_ENTITY_TASKS_DROP_QUERY_FILTER'
export const LEGAL_ENTITY_TASKS_RESIZE_COLUMNS = 'LEGAL_ENTITY_TASKS_RESIZE_COLUMNS'
export const LEGAL_ENTITY_TASKS_CHANGE_CHECKED = 'LEGAL_ENTITY_TASKS_CHANGE_CHECKED'
export const LEGAL_ENTITY_TASKS_SYNCRONIZED = 'LEGAL_ENTITY_TASKS_SYNCRONIZED'
export const LEGAL_ENTITY_TASKS_FINISH_LOADING = 'LEGAL_ENTITY_TASKS_FINISH_LOADING'
export const LEGAL_ENTITY_TASKS_CONTRACT_SENDING = 'LEGAL_ENTITY_TASKS_CONTRACT_SENDING'
export const LEGAL_ENTITY_TASKS_CHARGE_UPDATING = 'LEGAL_ENTITY_TASKS_CHARGE_UPDATING'
export const LEGAL_ENTITY_TASKS_REFRESH = 'LEGAL_ENTITY_TASKS_REFRESH'

export const LEGAL_ENTITY_NOTIFICATIONS_LOADING = 'LEGAL_ENTITY_NOTIFICATIONS_LOADING'
export const LEGAL_ENTITY_NOTIFICATIONS_SUCCESS = 'LEGAL_ENTITY_NOTIFICATIONS_SUCCESS'
export const LEGAL_ENTITY_NOTIFICATIONS_ERROR = 'LEGAL_ENTITY_NOTIFICATIONS_ERROR'
export const LEGAL_ENTITY_NOTIFICATIONS_LOAD_IS_REQUIRED =
  'LEGAL_ENTITY_NOTIFICATIONS_LOAD_IS_REQUIRED'
export const LEGAL_ENTITY_NOTIFICATIONS_LOADED = 'LEGAL_ENTITY_NOTIFICATIONS_LOADED'

export const LEGAL_ENTITY_PROFILE_LOADING = 'LEGAL_ENTITY_PROFILE_LOADING'
export const LEGAL_ENTITY_PROFILE_SUCCESS = 'LEGAL_ENTITY_PROFILE_SUCCESS'
export const LEGAL_ENTITY_PROFILE_EDIT = 'LEGAL_ENTITY_PROFILE_EDIT'
export const LEGAL_ENTITY_PROFILE_ERROR = 'LEGAL_ENTITY_PROFILE_ERROR'
export const LEGAL_ENTITY_PROFILE_LOAD_IS_REQUIRED = 'LEGAL_ENTITY_PROFILE_ERROR_LOAD_IS_REQUIRED'
export const LEGAL_ENTITY_PROFILE_LOADED = 'LEGAL_ENTITY_PROFILE_ERROR_LOADED'

export const LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADING = 'LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADING'
export const LEGAL_ENTITY_RESPONSIBLE_PERSON_SUCCESS = 'LEGAL_ENTITY_RESPONSIBLE_PERSON_SUCCESS'
export const LEGAL_ENTITY_RESPONSIBLE_PERSON_EDIT = 'LEGAL_ENTITY_RESPONSIBLE_PERSON_EDIT'
export const LEGAL_ENTITY_RESPONSIBLE_PERSON_ERROR = 'LEGAL_ENTITY_RESPONSIBLE_PERSON_ERROR'
export const LEGAL_ENTITY_RESPONSIBLE_PERSON_LOAD_IS_REQUIRED =
  'LEGAL_ENTITY_RESPONSIBLE_PERSON_ERROR_LOAD_IS_REQUIRED'
export const LEGAL_ENTITY_RESPONSIBLE_PERSON_LOADED = 'LEGAL_ENTITY_RESPONSIBLE_PERSON_ERROR_LOADED'
