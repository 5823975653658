import {
  CITIES_LOADING,
  CITIES_ADD,
  CITIES_EDIT,
  CITIES_ERROR,
  CITIES_SUCCESS,
  CITIES_DELETE,
  LOGOUT,
} from '../../constants'

const initialState = {
  items: [],
  isLoading: false,
  loadIsRequired: true,
  error: null,
}

export function cities(state = initialState, action) {
  switch (action.type) {
    case CITIES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case CITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: action.payload,
      }
    case CITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case CITIES_ADD:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
      }
    case CITIES_EDIT:
      return {
        ...state,
        isLoading: false,
        items: state.items.map((city) => {
          if (city.id === action.payload.id) {
            return action.payload
          }
          return city
        }),
      }
    case CITIES_DELETE:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        items: state.items.filter((city) => `${city.id}` !== action.payload.id),
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
