import {
  MASTER_REPORT_LOADING,
  MASTER_REPORT_SUCCESS,
  MASTER_REPORT_QUERY_FILTER,
  MASTER_REPORT_ERROR,
  MASTER_REPORT_DROP_QUERY_FILTER,
  LOGOUT,
} from '../../constants'
import moment from 'moment'

const initialState = {
  isLoading: false,
  report: {},
  loadIsRequired: false,
  query: null,
  error: null,
  start_date: moment().startOf('month').format('YYYY-MM-DD'),
  stop_date: moment().format('YYYY-MM-DD'),
}

export function masterReport(state = initialState, action) {
  switch (action.type) {
    case MASTER_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        report: action.payload,
        start_date: action.payload.start_date,
        stop_date: action.payload.stop_date,
      }
    case MASTER_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error,
      }
    case MASTER_REPORT_QUERY_FILTER:
      return {
        ...state,
        query: action.payload,
        loadIsRequired: true,
      }
    case MASTER_REPORT_DROP_QUERY_FILTER:
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
