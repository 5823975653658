import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

const browserHistory = createBrowserHistory()

const store = createStore(
  rootReducer,
  compose(applyMiddleware(routerMiddleware(browserHistory), thunkMiddleware)),
)

export const history = syncHistoryWithStore(browserHistory, store)

export default store
