import React from 'react'
import {
  LOGIN,
  LOGIN_IN_PROGRESS,
  LOGIN_ERROR,
  LOGOUT,
  DIASBLE_TOOLTIPS,
  USER_AVAILABLE_CITIES,
  USER_STATUS,
  USER_STATUS_CHANGING,
  NOTIFICATION_PERMISSION,
  REGISTER_FIREBASE_TOKEN,
  REGISTER_DEVICE,
  UPDATE_DEVICE,
  DEVICE_STATUS_IN_PROGRESS,
  UNREGISTER_DEVICE,
  LEGAL_ENTITY_REGISTER,
} from '../constants'
import { setUser, getAccess, removeUserColumnsSizes } from 'src/utils/localStorageManager'
import {
  Login,
  GetCrmCities,
  OperatorGetStatus,
  OperatorUpdateStatus,
  registerDevice,
  updateStatusDevice,
  unregisterDevice as unregister,
  legalEntityRegister,
  legalEntityValidate,
} from 'src/api'
import { registerToken } from 'src/firebase'
import { push } from 'react-router-redux'
import { showAlert, closeAlert } from './alert'
import { MANAGER, OPERATOR } from 'src/utils/constants'

function delay(ms) {
  return new Promise((resolve, reject) => setTimeout(resolve, ms))
}

const loading = () => {
  return { type: LOGIN_IN_PROGRESS }
}

const success = () => {
  return { type: LOGIN }
}

const fail = (error) => {
  return { type: LOGIN_ERROR, payload: { error } }
}

const setAvailableCities = (payload) => {
  return { type: USER_AVAILABLE_CITIES, payload }
}

const changeStatus = () => {
  return { type: USER_STATUS_CHANGING }
}

const saveToken = (payload) => {
  return { type: REGISTER_FIREBASE_TOKEN, payload }
}

const saveDevice = (payload) => {
  return { type: REGISTER_DEVICE, payload }
}

const updateDevice = (payload) => {
  return { type: UPDATE_DEVICE, payload }
}

const deviceStatusInProgress = (payload = true) => {
  return { type: DEVICE_STATUS_IN_PROGRESS, payload }
}

const unregisterDeviceAction = () => {
  return { type: UNREGISTER_DEVICE }
}

const entityRegister = () => {
  return { type: LEGAL_ENTITY_REGISTER }
}

export const notificationPermission = (payload) => {
  return { type: NOTIFICATION_PERMISSION, payload }
}

export const setStatus = (payload) => {
  return { type: USER_STATUS, payload }
}

export const logoutAction = () => {
  return { type: LOGOUT }
}

export const Auth = (credentials) => {
  return async (dispatch) => {
    dispatch(loading())

    try {
      const response = await Login(credentials)
      const { access, refresh, role, id } = response.data
      const formatRole = role.replace('.', '').split(' ').join('_')
      setUser(access, refresh, formatRole, id)
      dispatch(success())
      dispatch(closeAlert())
      dispatch(push('/'))
    } catch (error) {
      setUser()
      dispatch(fail(error))
      dispatch(showAlert(false, 'Неверные логин или пароль'))
    }
  }
}

export const disableTooltips = () => {
  return { type: DIASBLE_TOOLTIPS }
}

export const loadAvilableCities = () => {
  return async (dispatch) => {
    try {
      const response = await GetCrmCities()
      dispatch(setAvailableCities(response.data))
    } catch (error) {
      if (error.message === 'Network Error') {
        dispatch(showAlert(false, 'Сбой подключения к сети', []))
        return
      }
      dispatch(showAlert(false, 'Сбой установки доступных городов пользователя', []))
    }
  }
}

const statusReceived = (state) => {
  return state.user.status_received
}

const operatorGetStatus = () => {
  return async (dispatch) => {
    try {
      const res = await OperatorGetStatus()
      dispatch(setStatus(res.data.online))
    } catch (error) {
      dispatch(showAlert(false, 'Ошибка получения статуса пользователя', []))
    }
  }
}

export const receiveStatus = () => {
  return (dispatch, getState) => {
    if (statusReceived(getState())) return
    return dispatch(operatorGetStatus())
  }
}

export const changeOnline = () => {
  return async (dispatch, getState) => {
    dispatch(changeStatus())
    const { online, firebase_token } = getState().user
    const device_active = online ? true : false

    try {
      const res = await OperatorUpdateStatus({ online: !online })
      if (firebase_token) {
        const device_res = await updateStatusDevice(firebase_token, !device_active)
        dispatch(updateDevice(device_res.data.active))
      }
      setTimeout(() => {
        dispatch(setStatus(res.data.online))
      }, 2000)
    } catch (error) {
      dispatch(showAlert(false, 'Ошибка отправки статуса', []))
    }
  }
}

export const registerFirebaseToken = () => {
  return async (dispatch, getState) => {
    if (getState().user.firebase_token) {
      return
    }
    try {
      const token = await registerToken()
      dispatch(saveToken(token))
    } catch (error) {
      dispatch(
        showAlert(
          false,
          'Данный браузер не поддерживает всплывающие уведомления.Если хотите получать push-уведомления, воспользуйтесь Google Chrome',
        ),
      )
      dispatch(deviceStatusInProgress(false))
    }
  }
}

export const registerDeviceId = () => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!state.user.firebase_token) {
      return
    }
    try {
      const token = state.user.firebase_token
      const response = await registerDevice(token)
      dispatch(saveDevice(response.data.id))
    } catch (error) {
      dispatch(
        showAlert(false, 'Ошибка при регистрации устройства', error?.response?.data?.msg || []),
      )
      dispatch(deviceStatusInProgress(false))
    }
  }
}

export const updateDeviceId = (active) => {
  return async (dispatch, getState) => {
    if (!getState().user.firebase_token) {
      return
    }
    dispatch(deviceStatusInProgress())
    try {
      const { device_id, firebase_token } = getState().user
      if (!device_id && !firebase_token) return
      const response = await updateStatusDevice(firebase_token, active)
      setTimeout(() => dispatch(updateDevice(response.data.active)), 2000)
    } catch (error) {
      dispatch(
        showAlert(
          false,
          'Ошибка при изменении статуса устройства',
          error?.response?.data?.msg || [],
        ),
      )
      dispatch(deviceStatusInProgress(false))
    }
  }
}

export const unregisterDevice = () => {
  return async (dispatch, getState) => {
    dispatch(deviceStatusInProgress(true))
    const state = getState()
    if (!getAccess()) {
      return
    }
    if (!state.user.firebase_token) {
      return
    }
    try {
      const token = state.user.firebase_token
      await unregister(token)
      dispatch(unregisterDeviceAction())
    } catch (error) {
      dispatch(showAlert(false, 'Ошибка при удалении устройства'))
    }
  }
}

export const register = (registerData) => {
  return async (dispatch, getState) => {
    if (getState().user.isLoading) {
      return
    }
    dispatch(loading())
    try {
      const response = await legalEntityRegister(registerData)
      setTimeout(() => {
        dispatch(entityRegister())
      }, 1500)
      dispatch(
        showAlert(
          true,
          <span>
            Вы успешно зарегестрировались! На вашу почту <b>{response.data.email}</b> отправлено
            сообщение для верификации пользователя.
          </span>,
        ),
      )
    } catch (error) {
      dispatch(entityRegister())
      dispatch(showAlert(false, `Ошибка регистрации`, error?.response?.data?.msg ?? []))
    }
  }
}

export const validateLegalEntity = (params) => {
  return async (dispatch) => {
    try {
      const response = await legalEntityValidate(params)
      delay(500).then(() => dispatch(showAlert(true, response.data.msg)))
    } catch (error) {
      delay(500).then(() => dispatch(showAlert(false, error?.response?.data?.msg ?? 'Ошибка')))
    } finally {
      dispatch(push('/login'))
    }
  }
}

export const logout = () => {
  return async (dispatch, getState) => {
    if (getState().user.role === OPERATOR || getState().user.role === MANAGER) {
      const token = getState().user.firebase_token
      if (token) {
        await unregister(token)
      }
    }
    setUser()
    removeUserColumnsSizes()
    dispatch(logoutAction())
  }
}
