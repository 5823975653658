const env = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
const staging_host = 'https://domspas-staging.ru'
// const staging_host = 'http://192.168.1.22:8000'
const production_host = 'https://api.domspas.com'
// export const HOST = 'http://5.188.116.210:8000'
export const HOST = env === 'production' ? production_host : staging_host
// export const HOST = 'https://api.domspas.com'
export const AUTH = '/api/crm/login'
export const REFRESH = '/api/auth/refresh'

export const MASTERS = '/api/crm/handbook/masters'
export const TASKS = '/api/crm/tasks'
export const TASK = '/api/crm/task'
export const TASK_API = '/api/task'
export const UPLOAD_DOCUMENT = '/upload-document'
export const CITIES = '/api/crm/handbook/cities'
export const CATEGORY = '/api/category'
export const CITIESLIST = '/api/crm/handbook/cities'
export const CLIENTS = '/api/crm/clients'
export const ADDRESS = '/api/crm/address'
export const DIRECTOR_EFFICIENCY = '/api/crm/directors/efficiency'
export const PAYMENT_RULES = '/api/crm/handbook/payment-rules'
export const START_PAGE = '/api/crm/startpage'
export const CRM_EFFICIENCY = '/api/crm/efficiency'
export const HANDBOOK_STATUSES = '/api/crm/handbook/statuses'
export const HANDBOOK_CATEGORIES = '/api/crm/handbook/categories'
export const EMPLOYEES = '/api/crm/handbook/employees'
export const DIAGNOSTIC_TARIFFS = '/api/crm/handbook/diagnostic-tariffs'
export const TARIFFS = '/api/crm/handbook/tariffs'
export const CRM_LOGS = '/api/crm/logs'
export const SETTINGS_SUPPORT = '/api/crm/settings/support'
export const SETTINGS_MASTER = '/api/crm/settings/master'
export const FEEDBACK = '/api/crm/feedbacks'
export const NOTIFICATIONS = '/api/crm/notifications'
export const TRANSACTIONS = '/api/crm/transactions'
export const MASTERS_ONWORK = '/api/crm/masters-onwork'
export const OPERATORS_ONWORK = '/api/crm/operators-onwork'
export const LEGAL_ENTITY = '/api/crm/legal-entity'
export const ALL_CITIES = '/api/cities'

export const CRM_TASKS_REPORTS = '/api/crm/reports/tasks-count'
export const CRM_REPORTS_WORK_EFFICIENCY = '/api/crm/reports/work-efficiency'
export const CRM_REPORTS_MASTER = '/api/crm/reports/master'
export const FINANCIAL_REPORT = '/api/crm/reports/financial'
export const CRM_REPORTS_OPERATOR = '/api/crm/reports/operator-efficiency'

export const PAYMENT_RULE_CHOICES = '/api/crm/payment-rule-choices'
export const CRM_CITIES = '/api/crm/cities'
export const MASTERS_CHOICES = '/api/crm/masters-choices'
export const OPERATORS_CHOICES = '/api/crm/operators-choices'
export const ENTITIES_CHOICES = '/api/crm/legal-entities-choices'
export const TARIFF_CHOICES = '/api/crm/crm/tariff-choices'
export const AVAILABLE_OPERATORS = '/api/crm/available-operators'

export const FIREBASE_DEVICES_REGISTER = '/api/devices/crm/register'
export const DEVICES = '/api/devices/crm'

export const DISABLE = '/disable'
export const ENABLE = '/enable'
export const GET = '/get'
export const RESET = '/reset'
export const UPDATE = '/update'
export const SET_PROCESSED = '/set-processed'

export const AVATAR = '/avatar'
export const EFFICIENCY = '/efficiency'
export const XLSX = '/xlsx'
export const LOGS = '/logs'
export const XLSX_DAY = '/by-day/xlsx'

export const OPERATOR_ONLINE = '/api/crm/operator/online'

export const COMPLETE = '/status/complete'
export const IN_WORK = '/status/inwork'
export const CLIENT_THINKING = '/status/client-thinking'
export const REFUSE_USER = '/status/cancel/user'
export const REFUSE_COMPANY = '/status/cancel/company'
export const REFUSE_MASTER = '/status/cancel/master'
export const LOOKING_FOR_MASTER = '/status/looking-for-master'
export const SEND_CONTRACT = '/send-contract'
export const CHARGE_INCOME = '/charge-income'
export const DOWNLOAD_CONTRACT = '/download-contract'
export const VISA_READY = '/visa-ready'
export const VISA_ACCEPTED = '/visa-accepted'
export const VISA_PAID = '/visa-paid'

const API_LEGAL_ENTITY = '/api/legal-entity'
export const LEGAL_CRM_LEGAL_ENTITY = `/api/crm/legal-entity`
export const LEGAL_ENTITY_TASKS = `${API_LEGAL_ENTITY}/tasks`
export const LEGAL_ENTITY_NOTIFICATIONS = `${API_LEGAL_ENTITY}/notifications`
export const LEGAL_ENTITY_PROFILE = `${API_LEGAL_ENTITY}/information`
export const LEGAL_ENTITY_RESPONSIBLE_PERSON = `${API_LEGAL_ENTITY}/client`
export const LEGAL_ENTITY_VALIDATE = `${API_LEGAL_ENTITY}/validate`
