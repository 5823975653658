import {
  LOGIN,
  LOGIN_IN_PROGRESS,
  LOGIN_ERROR,
  LOGOUT,
  DIASBLE_TOOLTIPS,
  USER_AVAILABLE_CITIES,
  USER_STATUS,
  USER_STATUS_CHANGING,
  NOTIFICATION_PERMISSION,
  REGISTER_FIREBASE_TOKEN,
  REGISTER_DEVICE,
  UPDATE_DEVICE,
  DEVICE_STATUS_IN_PROGRESS,
  UNREGISTER_DEVICE,
  LEGAL_ENTITY_REGISTER,
} from '../constants'
import { isLoggedIn, getRole } from 'src/utils/localStorageManager'

const initialState = {
  isLogged: isLoggedIn(),
  role: getRole(),
  isLoading: false,
  error: null,
  tooltips: true,
  availableCities: [],
  success: false,
  status_received: false,
  status_in_progress: null,
  online: null,
  notification_permission: null,
  firebase_token: null,
  device_id: null,
  device_active: false,
  device_status_in_progress: true,
}

export function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      }
    case LOGIN:
      return {
        ...state,
        isLogged: true,
        role: getRole(),
        isLoading: false,
      }
    case LEGAL_ENTITY_REGISTER:
      return {
        ...state,
        isLoading: false,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error || 'Ошибка авторизации',
      }
    case USER_AVAILABLE_CITIES:
      return {
        ...state,
        isLoading: false,
        availableCities: action.payload,
        success: true,
      }
    case USER_STATUS:
      return {
        ...state,
        status_received: true,
        online: action.payload,
        status_in_progress: false,
      }
    case USER_STATUS_CHANGING:
      return {
        ...state,
        status_in_progress: true,
      }
    case DIASBLE_TOOLTIPS:
      return {
        ...state,
        tooltips: false,
      }
    case NOTIFICATION_PERMISSION:
      return {
        ...state,
        notification_permission: action.payload,
      }
    case REGISTER_FIREBASE_TOKEN:
      return {
        ...state,
        firebase_token: action.payload,
      }
    case DEVICE_STATUS_IN_PROGRESS:
      return {
        ...state,
        device_status_in_progress: action.payload,
      }
    case REGISTER_DEVICE:
      return {
        ...state,
        device_id: action.payload,
        device_active: true,
        device_status_in_progress: false,
      }
    case UPDATE_DEVICE:
      return {
        ...state,
        device_active: action.payload,
        device_status_in_progress: false,
      }
    case UNREGISTER_DEVICE:
      return {
        ...state,
        device_status_in_progress: false,
        device_active: false,
        device_id: null,
      }
    case LOGOUT:
      return {
        ...state,
        isLogged: false,
        isLoading: false,
        availableCities: [],
        role: null,
        online: null,
        status_received: false,
        status_in_progress: null,
        device_active: false,
        device_status_in_progress: false,
      }
    default:
      return state
  }
}
